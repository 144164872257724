import React, { ReactElement } from "react";
import classNames from "classnames";
import { Input } from "../Input";
import { PLACEHOLDER_USER_DATA } from "../../../App.consts";

const Billing = (): ReactElement => (
  <div className="c-floating-input mb-5 relative">
    {[
      {
        placeholder: PLACEHOLDER_USER_DATA.name,
        id: "name",
        labelText: "Name"
      },
      {
        type: "email",
        placeholder: PLACEHOLDER_USER_DATA.email,
        id: "email",
        labelText: "Email address"
      },
      {
        placeholder: PLACEHOLDER_USER_DATA.street1,
        id: "address-line-1",
        labelText: "Address Line 1"
      },
      {
        placeholder: PLACEHOLDER_USER_DATA.street2,
        id: "address-line-2",
        labelText: "Address Line 2"
      }
    ].map(({ placeholder, type, id, labelText }) => (
      <div key={id} className="mb-2">
        <Input type={type} placeholder={placeholder} id={id} labelText={labelText} />
      </div>
    ))}
    <div className="flex">
      {[
        {
          placeholder: PLACEHOLDER_USER_DATA.street3,
          id: "city",
          labelText: "City"
        },
        {
          placeholder: PLACEHOLDER_USER_DATA.street4,
          id: "state",
          labelText: "State"
        },
        {
          placeholder: PLACEHOLDER_USER_DATA.postcode,
          id: "zip-code",
          labelText: "ZIP Code"
        }
      ].map(({ placeholder, id, labelText }, index) => (
        <div key={id} className={classNames("mb-2 flex-grow", { "mr-2": index < 2 })}>
          <Input placeholder={placeholder} id={id} labelText={labelText} />
        </div>
      ))}
    </div>
    {[
      {
        placeholder: "1234 5678 9123 4567",
        id: "card-number",
        labelText: "Card Number"
      },
      {
        placeholder: "01/23",
        id: "expiry",
        labelText: "Expiry"
      },
      {
        type: "number",
        placeholder: "123",
        id: "cvc",
        labelText: "CVC"
      }
    ].map(({ placeholder, id, labelText, type }) => (
      <div key={id} className="mb-2">
        <Input type={type} placeholder={placeholder} id={id} labelText={labelText} />
      </div>
    ))}
  </div>
);

export default Billing;
