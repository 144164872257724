import React, { ReactElement, useEffect, useState } from "react";
import countryCurrencyCounty from "../../helpers/countryCurrencyCounty";
import CollectPointInput from "../CollectPointInput/CollectPointInput";
import { DeliveryFormProps } from "./DeliveryForm.types";

export const DeliveryForm = ({
  collectPoint,
  selectedCountry,
  setSelectedCountry
}: DeliveryFormProps): ReactElement => {
  const [selectedArea, setSelectedArea] = useState<string>("Select County");

  useEffect(() => {
    if (collectPoint?.address.county) {
      setSelectedArea(collectPoint.address.county);
    }

    if (collectPoint?.address.country) {
      setSelectedCountry(collectPoint.address.country);
    }
  }, [collectPoint, setSelectedCountry]);

  return (
    <div className="c-floating-input mb-5 relative">
      <div className="flex flex-col border border-solid border-hubbox-gray-border-2 pl-4 mb-2">
        {/* FIXME: For some reason, ES Lint complains despite providing <label> 'htmlFor' and <select> 'id' values */}
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="country-region" className="text-gray-700 text-sm">
          Country/region
        </label>
        <select
          id="country-region"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          className="form-select font-light text-gray-900 border-none p-0 pr-10 mt-1">
          <option key="Select Country" disabled value="Select Country">
            Select Country
          </option>

          {Object.entries(countryCurrencyCounty).map((country) => (
            <option key={country[0]} value={country[0]}>
              {country[1].countryLabel}
            </option>
          ))}
        </select>
      </div>
      {[
        {
          placeholder: "John",
          id: "first-name",
          labelText: "First Name (optional)"
        },
        { placeholder: "Smith", id: "last-name", labelText: "Last Name" },
        {
          placeholder: "123 Test Street",
          id: "address",
          labelText: "Address",
          collectPointInfo: collectPoint?.address.street1
        }
      ].map(({ placeholder, id, labelText, collectPointInfo }) => (
        <div className="mb-2" key={id}>
          <CollectPointInput
            placeholder={placeholder}
            id={id}
            labelText={labelText}
            collectPointInfo={collectPointInfo}
          />
        </div>
      ))}
      <div className="flex gap-2 flex-wrap">
        <div key="city" className="flex-grow">
          <CollectPointInput
            placeholder="Enter City"
            id="city"
            labelText="City"
            collectPointInfo={collectPoint?.address.city}
          />
        </div>

        <div className="flex flex-grow">
          <div className="flex flex-col flex-grow border border-solid border-hubbox-gray-border-2 pl-4">
            <label htmlFor="area" className="text-gray-700 text-sm">
              {countryCurrencyCounty[selectedCountry]
                ? countryCurrencyCounty[selectedCountry].areaLabel
                : "County"}
            </label>
            <select
              id="area"
              value={selectedArea}
              onChange={(e) => setSelectedArea(e.target.value)}
              className="form-select font-light text-gray-900 border-none p-0 pr-10">
              <option
                key="Select Area"
                disabled
                value={
                  countryCurrencyCounty[selectedCountry]
                    ? `Select ${countryCurrencyCounty[selectedCountry].areaLabel}`
                    : "Select County"
                }>
                {countryCurrencyCounty[selectedCountry]
                  ? `Select ${countryCurrencyCounty[selectedCountry].areaLabel}`
                  : "Select County"}
              </option>

              {countryCurrencyCounty[selectedCountry] &&
                Object.entries(countryCurrencyCounty[selectedCountry].areas).map((area) => (
                  <option key={area[0]} value={area[0]}>
                    {area[1]}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div key="postal-code" className="flex-grow">
          <CollectPointInput
            placeholder="Enter Postal Code"
            id="postal-code"
            labelText="Postal code"
            collectPointInfo={collectPoint?.address.postcode}
          />
        </div>
      </div>
    </div>
  );
};
