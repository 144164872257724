import { LOCALE_CODES, NETWORKS, STYLES } from "../App.consts";

export const LABEL_LOCALE_LOOKUP = {
  [LOCALE_CODES.EN_US]: "English (US)",
  [LOCALE_CODES.EN]: "English (UK)",
  [LOCALE_CODES.FR]: "Français",
  [LOCALE_CODES.DE]: "Deutsch",
  [LOCALE_CODES.ES]: "Español",
  [LOCALE_CODES.IT]: "Italiano",
  [LOCALE_CODES.NL]: "Nederlands"
} as const;

export const LABEL_NETWORK_LOOKUP = {
  [NETWORKS.UPS]: "Ship to a UPS Access Point",
  [NETWORKS.DPD]: "DPD Pickup",
  [NETWORKS.HUBBOX]: "HubBox UK Click & Collect",
  [NETWORKS.HERMES]: "Hermes Pickup",
  [NETWORKS.EVRI]: "Evri Pickup"
} as const;

export const LABEL_STYLE_LOOKUP = {
  [STYLES.DEFAULT]: "Default style",
  [STYLES.DEMO]: "Demo style"
} as const;
