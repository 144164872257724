import React, { ReactElement } from "react";
import { DeliveryOptionPros } from "./DeliveryOption.types";
import countryCurrencyCounty from "../../helpers/countryCurrencyCounty";

const DeliveryOption = ({
  inputId,
  inputText,
  price,
  expectedDeliveryPeriod,
  icon,
  benefitsInfo,
  selectedMethod,
  setSelectedMethod,
  setIsWidgetOpen,
  selectedCountry
}: DeliveryOptionPros): ReactElement => {
  const onSelectOption = () => {
    setSelectedMethod({
      inputId,
      inputText,
      price,
      expectedDeliveryPeriod
    });

    if (inputText.includes("Local Pickup")) {
      setIsWidgetOpen(true);
    } else {
      setIsWidgetOpen(false);
    }
  };

  const currency = countryCurrencyCounty[selectedCountry]?.currency;

  const decoratePrice = () => {
    if (price === 0) {
      return <div>Free</div>;
    }

    if (!currency) {
      return <div>{price}</div>;
    }

    return (
      <div>
        <span>{currency}</span>
        {price}
      </div>
    );
  };

  return (
    <>
      <label
        htmlFor={inputId}
        className="border border-solid border-hubbox-gray-border-2 p-4 flex gap-4 self-stretch items-baseline">
        <div className="shipping-method-test-c-radio-segment__input">
          <input
            type="radio"
            name="delivery"
            id={inputId}
            className={`sr-only cy-${inputId}`}
            readOnly
            checked={selectedMethod?.inputId === inputId}
            onClick={onSelectOption}
          />
          {/* FIXME: For some reason, ES Lint complains despite providing <label> 'htmlFor' and <input> 'id' values */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor={inputId}>
            <i />
          </label>
        </div>

        <div className="flex flex-col gap-3 flex-grow">
          <div className="flex gap-4">
            <div className="flex flex-col items-start flex-grow">
              {icon ? (
                <div className="flex gap-2 items-center">
                  <span>{icon}</span>
                  <div className="text-1xl md:text-2xl">{inputText}</div>
                </div>
              ) : (
                <div className="text-1xl md:text-2xl">{inputText}</div>
              )}
              <div>{expectedDeliveryPeriod}</div>
            </div>
            {decoratePrice()}
          </div>

          {benefitsInfo && <div>{benefitsInfo}</div>}
        </div>
      </label>
    </>
  );
};

export default DeliveryOption;
