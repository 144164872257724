import React, { ReactElement } from "react";
import Input from "./Input/Input";

const Billing = (): ReactElement => (
  <div className="c-floating-input mb-5 relative">
    {[
      {
        placeholder: "1234 5678 9123 4567",
        id: "card-number",
        labelText: "Card Number",
        defaultValue: "4111111111111111",
        disabled: true
      },
      {
        placeholder: "01/23",
        id: "expiration-date",
        labelText: "Expiration Date (MM/YY)",
        defaultValue: "01/40",
        disabled: true
      },
      {
        type: "number",
        placeholder: "123",
        id: "security-code",
        labelText: "Security Code",
        defaultValue: "123",
        disabled: true
      },
      {
        placeholder: "MR JOHN SMITH",
        id: "name-on-card",
        labelText: "Name on card"
      }
    ].map(({ placeholder, id, labelText, type, defaultValue, disabled }) => (
      <div key={id} className="mb-2">
        <Input
          type={type}
          placeholder={placeholder}
          id={id}
          labelText={labelText}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    ))}
  </div>
);

export default Billing;
