export const FONT_VALUES = {
  montserrat: "montserrat",
  lato: "lato",
  "futura-pt": "futura-pt"
};

export const FONT_INPUT_PROPS = [
  {
    title: "Montserrat",
    value: FONT_VALUES.montserrat
  },
  {
    title: "Lato",
    value: FONT_VALUES.lato
  },
  {
    title: "Futura",
    value: FONT_VALUES["futura-pt"]
  }
];

export const HEADER_FONT_WEIGHT_VALUES = {
  light: "300",
  normal: "400",
  bold: "600"
};

export const HEADER_FONT_WEIGHT = [
  {
    title: "Light",
    value: HEADER_FONT_WEIGHT_VALUES.light
  },
  {
    title: "Normal",
    value: HEADER_FONT_WEIGHT_VALUES.normal
  },
  {
    title: "Bold",
    value: HEADER_FONT_WEIGHT_VALUES.bold
  }
];

export const BOPIS_VALUES = {
  enabled: "enabled",
  disabled: "disabled"
};

export const BOPIS_INPUT_PROPS = [
  {
    title: "Enabled",
    value: BOPIS_VALUES.enabled
  },
  {
    title: "Disabled",
    value: BOPIS_VALUES.disabled
  }
];

/**
 * Remember to change the font in DynamioDB aswell
 * or else the widget wont have the default font weight
 *
 */
export const DEFAULT_HEADER_FONT_WEIGHT = HEADER_FONT_WEIGHT_VALUES.bold;

/**
 * Remember to change the font in DynamioDB aswell
 * or else the widget wont have the default font
 *
 */
export const DEFAULT_FONT_VALUE = FONT_VALUES.montserrat;

/**
 * Remember to change the font in DynamioDB aswell
 * or else the widget wont have the default color
 *
 */
export const DEFAULT_BUTTON_COLOR_VALUE = "#000000";

/**
 * Remember to change the color in DynamioDB aswell
 * or else the widget wont have the default color
 *
 */
export const DEFAULT_TEXT_COLOR_VALUE = "#000000";

/**
 * Remember to change the color in DynamioDB aswell
 * or else the widget wont have the default color
 *
 */
export const DEFAULT_DISTANCE_BACKGROUND_VALUE = "#c2e6b1";

/**
 * Remember to change the color in DynamioDB aswell
 * or else the widget wont have the default color
 *
 */
export const DEFAULT_DISTANCE_COLOR_VALUE = "#319f0f";

/**
 * Remember to change the BOPIS_INPUT_PROPS in DynamioDB aswell
 * or else the widget wont have the default color
 *
 */
export const DEFAULT_BOPIS_VALUE = BOPIS_VALUES.disabled;

export const UPS_NETWORK = "ups_bridge";
export const DPD_NETWORK = "testdrive_dpd";
export const HUBBOX_NETWORK = "testdrive_hubbox";
export const HERMES_NETWORK = "testdrive_hermes";
export const EVRI_NETWORK = "evri_gb";

export const BOPIS_UPS_NETWORK = "testdrive_ups_bopis";
export const BOPIS_DPD_NETWORK = "testdrive_dpd_bopis";
export const BOPIS_HUBBOX_NETWORK = "testdrive_hubbox_bopis";
export const BOPIS_HERMES_NETWORK = "testdrive_hermes_bopis";
export const BOPIS_EVRI_NETWORK = "evri_gb_bopis";

export enum COLOR_PICKERS {
  BUTTON = "BUTTON",
  TEXT = "TEXT",
  DISTANCE_BACKGROUND = "DISTANCE_BACKGROUND",
  DISTANCE_TEXT = "DISTANCE_TEXT"
}

export enum CONFIGURATION_ACTION_TYPES {
  BUTTON_COLOR = "BUTTON_COLOR",
  CONFIGURATION = "CONFIGURATION",
  FONT = "FONT",
  TEXT_COLOR = "TEXT_COLOR",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  HEADER_FONT_WEIGHT = "HEADER_FONT_WEIGHT",
  DISTANCE_CARD_COLOR = "DISTANCE_CARD_COLOR",
  DISTANCE_CARD_TEXT_COLOR = "DISTANCE_CARD_TEXT_COLOR",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  BOPIS = "BOPIS",
  NETWORK = "NETWORK"
}
