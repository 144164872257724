/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useEffect } from "react";
import type { Style } from "../App.types";

interface Props {
  iframeRef: MutableRefObject<HTMLIFrameElement | null>;
  style?: Style;
}

/** Use widget style from `App.css`. */
export default function useHbStyle({ iframeRef, style }: Props): void {
  const updateStyle = () =>
    iframeRef.current?.contentDocument?.body.classList[style === "demo" ? "add" : "remove"]("demo");

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe?.addEventListener("load", updateStyle, { once: true });
    return () => iframe?.removeEventListener("load", updateStyle);
  }, []);

  useEffect(updateStyle, [style]);
}
