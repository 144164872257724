import React, { ReactElement } from "react";

const DeliveryOption = ({
  inputId,
  inputText
}: {
  inputId: string;
  inputText: string;
}): ReactElement => (
  <>
    <label htmlFor={inputId} className="border border-solid border-black p-4 flex">
      <div className="c-radio-segment__input">
        <input
          type="radio"
          name="delivery"
          id={inputId}
          checked
          className={`sr-only cy-${inputId}`}
          readOnly
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={inputId}>
          <i />
        </label>
      </div>

      <div className="text-1xl md:text-2xl ml-5">{inputText}</div>
    </label>
  </>
);

export default DeliveryOption;
