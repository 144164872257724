import { Locale } from "../../App.types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getCurrencySymbol(locale: Locale | undefined) {
  switch (locale) {
    case "en-us":
      return "$";
    case "en":
      return "£";
    default:
      return "€";
  }
}
