interface CountryCurrencyCounty {
  [countryCode: string]: {
    countryLabel: string;
    currency: string;
    areaLabel: string;
    areas: {
      [key: string]: string;
    };
  };
}

const countryCurrencyCounty: CountryCurrencyCounty = {
  CA: {
    countryLabel: "Canada",
    currency: "CAD",
    // Example values:
    // "city": "TORONTO",
    // "locality": "ON",
    // "county": "ON",
    // "region": "ON",
    // "country": "CA",

    // Counties
    // Got it from here: https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/prepare-life-canada/provinces-territories.html
    // And here: https://www12.statcan.gc.ca/census-recensement/2021/ref/dict/tab/index-eng.cfm?ID=t1_8
    // Keys match what we get back from collect point API under locality ,county and region values - all the same
    // Values can be the UI labels
    areaLabel: "Province/Territory",
    areas: {
      NL: "Newfoundland and Labrador",
      PE: "Prince Edward Island",
      NS: "Nova Scotia",
      NB: "New Brunswick",
      QC: "Quebec",
      ON: "Ontario",
      MB: "Manitoba",
      SK: "Saskatchewan",
      AB: "Alberta",
      BC: "British Columbia",
      YT: "Yukon",
      NT: "Northwest Territories",
      NU: "Nunavut"
    }
  },
  GB: {
    countryLabel: "United Kingdom",
    currency: "£",
    // Example values:
    // "city": "MANCHESTER",
    // "locality": "",
    // "county": "",
    // "region": "",
    // "country": "GB",
    // Counties
    // From Jon's list here: https://hub-box.slack.com/archives/C1E7K1BB5/p1722858290137259?thread_ts=1722855830.449369&cid=C1E7K1BB5
    // FIXME: I think this list might be incomplete (e.g. no London/Greater London)?
    areaLabel: "County",
    areas: {
      Aberdeenshire: "Aberdeenshire",
      Anglesey: "Anglesey",
      Angus: "Angus",
      Antrim: "Antrim",
      Armagh: "Armagh",
      Ayrshire: "Ayrshire",
      Banffshire: "Banffshire",
      Bedfordshire: "Bedfordshire",
      Berkshire: "Berkshire",
      Berwickshire: "Berwickshire",
      Brecknockshire: "Brecknockshire",
      Buckinghamshire: "Buckinghamshire",
      Caernarfonshire: "Caernarfonshire",
      Cambridgeshire: "Cambridgeshire",
      Cardiganshire: "Cardiganshire",
      Carmarthenshire: "Carmarthenshire",
      Cheshire: "Cheshire",
      Clackmannanshire: "Clackmannanshire",
      Cornwall: "Cornwall",
      Cumberland: "Cumberland",
      Denbighshire: "Denbighshire",
      Derbyshire: "Derbyshire",
      Devon: "Devon",
      Dorset: "Dorset",
      Down: "Down",
      Dumfriesshire: "Dumfriesshire",
      Dunbartonshire: "Dunbartonshire",
      Durham: "Durham",
      "East Lothian": "East Lothian",
      Essex: "Essex",
      Fermanagh: "Fermanagh",
      Fife: "Fife",
      Flintshire: "Flintshire",
      Glamorgan: "Glamorgan",
      Gloucestershire: "Gloucestershire",
      Hampshire: "Hampshire",
      Herefordshire: "Herefordshire",
      Hertfordshire: "Hertfordshire",
      Huntingdonshire: "Huntingdonshire",
      "Inverness-shire": "Inverness-shire",
      Kent: "Kent",
      Kincardineshire: "Kincardineshire",
      "Kinross-shire": "Kinross-shire",
      Kirkcudbrightshire: "Kirkcudbrightshire",
      Lanarkshire: "Lanarkshire",
      Lancashire: "Lancashire",
      Leicestershire: "Leicestershire",
      Londonderry: "Londonderry",
      Merionethshire: "Merionethshire",
      Middlesex: "Middlesex",
      Midlothian: "Midlothian",
      Monmouthshire: "Monmouthshire",
      Montgomeryshire: "Montgomeryshire",
      Nairnshire: "Nairnshire",
      Norfolk: "Norfolk",
      Northamptonshire: "Northamptonshire",
      Northumberland: "Northumberland",
      Nottinghamshire: "Nottinghamshire",
      Oxfordshire: "Oxfordshire",
      Peeblesshire: "Peeblesshire",
      Pembrokeshire: "Pembrokeshire",
      Perthshire: "Perthshire",
      Radnorshire: "Radnorshire",
      Renfrewshire: "Renfrewshire",
      "Ross-shire": "Ross-shire",
      Roxburghshire: "Roxburghshire",
      Rutland: "Rutland",
      Selkirkshire: "Selkirkshire",
      Shropshire: "Shropshire",
      Somerset: "Somerset",
      Staffordshire: "Staffordshire",
      Stirlingshire: "Stirlingshire",
      Suffolk: "Suffolk",
      Surrey: "Surrey",
      Sussex: "Sussex",
      Tyrone: "Tyrone",
      Warwickshire: "Warwickshire",
      "West Lothian": "West Lothian",
      Westmorland: "Westmorland",
      Wigtownshire: "Wigtownshire",
      Wiltshire: "Wiltshire",
      Worcestershire: "Worcestershire"
    }
  },
  FR: {
    countryLabel: "France",
    currency: "€",
    // Example values
    // "city": "MONTRACOL",
    // "locality": "",
    // "county": "",
    // "region": "",
    // "country": "FR",

    // Departments
    // From: https://www.data.gouv.fr/en/datasets/departements-et-leurs-regions/#/resources
    // Using sandbox ups collect point API - Haven't seen departments populated in any of the ups or screwfix collect points in France - potentially they aren't being populated on the backend?
    areaLabel: "Department",
    areas: {
      Ain: "Ain",
      Aisne: "Aisne",
      Allier: "Allier",
      "Alpes-de-Haute-Provence": "Alpes-de-Haute-Provence",
      "Hautes-Alpes": "Hautes-Alpes",
      "Alpes-Maritimes": "Alpes-Maritimes",
      Ardèche: "Ardèche",
      Ardennes: "Ardennes",
      Ariège: "Ariège",
      Aube: "Aube",
      Aude: "Aude",
      Aveyron: "Aveyron",
      "Bouches-du-Rhône": "Bouches-du-Rhône",
      Calvados: "Calvados",
      Cantal: "Cantal",
      Charente: "Charente",
      "Charente-Maritime": "Charente-Maritime",
      Cher: "Cher",
      Corrèze: "Corrèze",
      "Côte-d'Or": "Côte-d'Or",
      "Côtes-d'Armor": "Côtes-d'Armor",
      Creuse: "Creuse",
      Dordogne: "Dordogne",
      Doubs: "Doubs",
      Drôme: "Drôme",
      Eure: "Eure",
      "Eure-et-Loir": "Eure-et-Loir",
      Finistère: "Finistère",
      "Corse-du-Sud": "Corse-du-Sud",
      "Haute-Corse": "Haute-Corse",
      Gard: "Gard",
      "Haute-Garonne": "Haute-Garonne",
      Gers: "Gers",
      Gironde: "Gironde",
      Hérault: "Hérault",
      "Ille-et-Vilaine": "Ille-et-Vilaine",
      Indre: "Indre",
      "Indre-et-Loire": "Indre-et-Loire",
      Isère: "Isère",
      Jura: "Jura",
      Landes: "Landes",
      "Loir-et-Cher": "Loir-et-Cher",
      Loire: "Loire",
      "Haute-Loire": "Haute-Loire",
      "Loire-Atlantique": "Loire-Atlantique",
      Loiret: "Loiret",
      Lot: "Lot",
      "Lot-et-Garonne": "Lot-et-Garonne",
      Lozère: "Lozère",
      "Maine-et-Loire": "Maine-et-Loire",
      Manche: "Manche",
      Marne: "Marne",
      "Haute-Marne": "Haute-Marne",
      Mayenne: "Mayenne",
      "Meurthe-et-Moselle": "Meurthe-et-Moselle",
      Meuse: "Meuse",
      Morbihan: "Morbihan",
      Moselle: "Moselle",
      Nièvre: "Nièvre",
      Nord: "Nord",
      Oise: "Oise",
      Orne: "Orne",
      "Pas-de-Calais": "Pas-de-Calais",
      "Puy-de-Dôme": "Puy-de-Dôme",
      "Pyrénées-Atlantiques": "Pyrénées-Atlantiques",
      "Hautes-Pyrénées": "Hautes-Pyrénées",
      "Pyrénées-Orientales": "Pyrénées-Orientales",
      "Bas-Rhin": "Bas-Rhin",
      "Haut-Rhin": "Haut-Rhin",
      Rhône: "Rhône",
      "Haute-Saône": "Haute-Saône",
      "Saône-et-Loire": "Saône-et-Loire",
      Sarthe: "Sarthe",
      Savoie: "Savoie",
      "Haute-Savoie": "Haute-Savoie",
      Paris: "Paris",
      "Seine-Maritime": "Seine-Maritime",
      "Seine-et-Marne": "Seine-et-Marne",
      Yvelines: "Yvelines",
      "Deux-Sèvres": "Deux-Sèvres",
      Somme: "Somme",
      Tarn: "Tarn",
      "Tarn-et-Garonne": "Tarn-et-Garonne",
      Var: "Var",
      Vaucluse: "Vaucluse",
      Vendée: "Vendée",
      Vienne: "Vienne",
      "Haute-Vienne": "Haute-Vienne",
      Vosges: "Vosges",
      Yonne: "Yonne",
      "Territoire de Belfort": "Territoire de Belfort",
      Essonne: "Essonne",
      "Hauts-de-Seine": "Hauts-de-Seine",
      "Seine-Saint-Denis": "Seine-Saint-Denis",
      "Val-de-Marne": "Val-de-Marne",
      "Val-d'Oise": "Val-d'Oise",
      Guadeloupe: "Guadeloupe",
      Martinique: "Martinique",
      Guyane: "Guyane",
      "La Réunion": "La Réunion",
      Mayotte: "Mayotte"
    }
  },
  US: {
    countryLabel: "United States",
    currency: "$",
    // States
    areaLabel: "State",
    // Example values
    // "city": "BOSTON",
    // "locality": "MA",
    // "county": "MA",
    // "region": "MA",
    // "country": "US",
    // "postcode": "02116",

    // 50 states list from here: https://usastatescode.com/state-array-json
    areas: {
      AL: "Alabama",
      AK: "Alaska",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming"
    }
  }
};

export default countryCurrencyCounty;
