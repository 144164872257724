import React, { Dispatch, ReactElement, SetStateAction } from "react";
import { ReactComponent as MonitorIcon } from "./monitor.svg";
import { ReactComponent as MonitorSelectedIcon } from "./monitor-selected.svg";
import { ReactComponent as PhoneIcon } from "./phone.svg";
import { ReactComponent as PhoneSelectedIcon } from "./phone-selected.svg";
import { ReactComponent as ToggleLeftIcon } from "./toggle-left.svg";
import { ReactComponent as ToggleRightIcon } from "./toggle-right.svg";

const DeviceSwitch = ({
  isMobile,
  setIsMobile
}: {
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
}): ReactElement => (
  <button
    type="button"
    aria-label={`${isMobile ? "Mobile" : "Desktop"} View`}
    onClick={() => {
      setIsMobile(!isMobile);
    }}
    className="flex">
    <div>{!isMobile ? <MonitorSelectedIcon /> : <MonitorIcon />}</div>
    <div className="pr-2" />
    <div>{isMobile ? <ToggleRightIcon /> : <ToggleLeftIcon />}</div>
    <div className="pr-0.5" />
    <div>{isMobile ? <PhoneSelectedIcon /> : <PhoneIcon />}</div>
  </button>
);

export default DeviceSwitch;
