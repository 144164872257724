import React, { ReactElement } from "react";
import { AddressProps } from "./AddressProps.types";

export const Address = ({
  street1,
  street2,
  street3,
  street4,
  city,
  region,
  postcode,
  name
}: AddressProps): ReactElement => (
  <address className="not-italic font-medium text-sm uppercase">
    <div>{name}</div>
    <div>{street1}</div>
    <div>{street2}</div>
    <div>{street3}</div>
    <div>{street4}</div>
    <div>
      {city}
      {region && `, ${region}`}
    </div>
    <div>{postcode}</div>
  </address>
);

export default Address;
