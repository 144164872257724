import React, { ReactElement } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Configuration } from "./types/Configuration";
import { DeepPartial } from "./types/Utilies/DeepPartial";
import { ROUTES } from "./App.helpers";
import { Landing } from "./Landing/Landing";
import AppCheckout from "./AppCheckout";
import AppConfigurator from "./AppConfigurator";
import Checkout from "./ShippingMethodTest/components/Checkout";

declare global {
  interface Window {
    configuration: DeepPartial<Configuration>;
  }
}

const App = (): ReactElement => (
  <Router>
    <Switch>
      <Route path={ROUTES.CHECKOUT}>
        <AppCheckout />
      </Route>
      <Route path={ROUTES.CONFIGURATOR}>
        <AppConfigurator />
      </Route>
      <Route path={ROUTES.TESTING_SHIPPING_METHODS}>
        <Checkout />
      </Route>
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  </Router>
);

export default App;
