import React, { ReactElement } from "react";
import { InputProps } from "./Input.types";

const Input = ({ type = "text", placeholder, id, labelText }: InputProps): ReactElement => (
  <div className="c-floating-input relative">
    <input
      type={type}
      id={id}
      className="border border-black focus:outline-none w-full p-4 h-12 font-light placeholder-gray-500 text-gray-900"
      placeholder={placeholder}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label
      htmlFor={id}
      className="absolute font-medium top-0.5 left-1 px-3 py-3 h-full text-gray-900 pointer-events-none transform origin-left transition-all duration-100 ease-in-out ">
      {labelText}
    </label>
  </div>
);

export default Input;
