import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import classNames from "classnames";
import { HexColorPicker } from "react-colorful";
import { useHistory, useParams } from "react-router-dom";
import { DeviceSwitch } from "./components/DeviceSwitch";
import { Color, ConfiguratorAction, ConfiguratorProps } from "./Configurator.types";
import "react-colorful/dist/index.css";

import {
  BOPIS_INPUT_PROPS,
  COLOR_PICKERS,
  CONFIGURATION_ACTION_TYPES,
  DEFAULT_BOPIS_VALUE,
  DEFAULT_BUTTON_COLOR_VALUE,
  DEFAULT_DISTANCE_BACKGROUND_VALUE,
  DEFAULT_DISTANCE_COLOR_VALUE,
  DEFAULT_TEXT_COLOR_VALUE,
  FONT_INPUT_PROPS,
  HEADER_FONT_WEIGHT,
  BOPIS_VALUES
} from "./Configurator.consts";
import { MobilePhone } from "./components/MobilePhone";
import { debounce } from "./Configurator.helpers";
import { Locale, Network, Style, UrlParameters } from "../App.types";
import {
  LABEL_LOCALE_LOOKUP,
  LABEL_NETWORK_LOOKUP,
  LABEL_STYLE_LOOKUP
} from "../Landing/Landing.consts";
import { getConfiguratorPath } from "../App.helpers";
import useHbStyle from "../hooks/useHbStyle";

/**
 *
 * The Configurator renders the Checkout in an iframe
 *
 * @param checkoutStep
 * @param setConfiguration
 * @param iframeRef
 * @param showOverlay
 * @param collectPoint
 * @constructor
 */
export const Configurator = ({
  dispatchConfiguration,
  iframeRef,
  showOverlay,
  checkoutStep,
  configuration
}: ConfiguratorProps): ReactElement => {
  const history = useHistory();

  const { network, locale, style } = useParams<UrlParameters>();

  const [isBopisState, setIsBopisState] = useState(false);

  const initialNetworkParamRef = useRef<Network>();
  const initialLocaleParamRef = useRef<Locale>();
  const initialStyleParamRef = useRef<Style>();

  useHbStyle({ iframeRef, style });

  /**
   *
   * We set the initial network and locale params.
   * We don't want the checkout url to update
   * when the configurator updates the parameters.
   * Because that is set through Post message.
   *
   */
  useEffect(() => {
    if (network && !initialNetworkParamRef.current) {
      initialNetworkParamRef.current = network;
    }

    if (locale && !initialLocaleParamRef.current) {
      initialLocaleParamRef.current = locale;
    }

    if (style && !initialStyleParamRef.current) {
      initialStyleParamRef.current = style;
    }
  }, [network, locale, style]);

  useEffect(() => {
    if (network) {
      dispatchConfiguration({
        type: CONFIGURATION_ACTION_TYPES.NETWORK,
        value: network,
        meta: {
          isBopis: isBopisState
        }
      });
    }
  }, [network, dispatchConfiguration, isBopisState]);

  const [isMobile, setIsMobile] = useState(false);

  const [buttonColor, setButtonColor] = useState(DEFAULT_BUTTON_COLOR_VALUE);
  const [fontColor, setFontColor] = useState(DEFAULT_TEXT_COLOR_VALUE);
  const [distanceColor, setDistanceColor] = useState(DEFAULT_DISTANCE_BACKGROUND_VALUE);
  const [distanceTextColor, setDistanceTextColor] = useState(DEFAULT_DISTANCE_COLOR_VALUE);

  const [activeColorPicker, setActiveColorPicker] = useState<COLOR_PICKERS | null>(null);

  const debounceConfiguration = useRef(debounce(dispatchConfiguration));

  // Color pickers
  const buttonColorRef = useRef<HTMLDivElement>(null);
  const textColorRef = useRef<HTMLDivElement>(null);
  const distanceColorRef = useRef<HTMLDivElement>(null);
  const distanceTextColorRef = useRef<HTMLDivElement>(null);

  // Color pickers wrapper
  const colorPickerWrapperRef = useRef<HTMLDivElement>(null);

  // Customisation options wrapper
  const customizationWrapperRef = useRef<HTMLDivElement>(null);
  const configurationWrapperRef = useRef<HTMLDivElement>(null);

  const hasMounted = useRef(false);

  const colorRefLookup = useRef<Record<COLOR_PICKERS, HTMLDivElement | null>>();

  const toggleColorPicker = useCallback(
    (colorPicker: COLOR_PICKERS) => {
      setActiveColorPicker(activeColorPicker ? null : colorPicker);
    },
    [activeColorPicker, setActiveColorPicker]
  );

  const colorPickerStateLookup = useMemo(
    (): Record<COLOR_PICKERS, [Color, Dispatch<SetStateAction<Color>>]> => ({
      [COLOR_PICKERS.BUTTON]: [buttonColor, setButtonColor],
      [COLOR_PICKERS.TEXT]: [fontColor, setFontColor],
      [COLOR_PICKERS.DISTANCE_BACKGROUND]: [distanceColor, setDistanceColor],
      [COLOR_PICKERS.DISTANCE_TEXT]: [distanceTextColor, setDistanceTextColor]
    }),
    [
      buttonColor,
      setButtonColor,
      fontColor,
      setFontColor,
      distanceColor,
      setDistanceColor,
      distanceTextColor,
      setDistanceTextColor
    ]
  );

  /**
   *
   * Update color position when active color picker is selected
   * and before DOM paint
   *
   * */
  useLayoutEffect(() => {
    if (
      activeColorPicker &&
      colorRefLookup.current &&
      colorRefLookup.current[activeColorPicker] &&
      colorPickerWrapperRef.current
    ) {
      const activeColorElem = colorRefLookup.current[activeColorPicker] as HTMLDivElement;

      colorPickerWrapperRef.current.style.top = `${
        activeColorElem.getBoundingClientRect().top + activeColorElem.offsetHeight
      }px`;
    }
  }, [
    customizationWrapperRef,
    activeColorPicker,
    colorRefLookup,
    colorPickerWrapperRef,
    showOverlay
  ]);

  useEffect(() => {
    if (activeColorPicker === null || !hasMounted.current) return;

    const actionLookup: Record<COLOR_PICKERS, ConfiguratorAction> = {
      [COLOR_PICKERS.BUTTON]: {
        type: CONFIGURATION_ACTION_TYPES.BUTTON_COLOR,
        value: buttonColor
      },
      [COLOR_PICKERS.TEXT]: {
        type: CONFIGURATION_ACTION_TYPES.TEXT_COLOR,
        value: fontColor
      },
      [COLOR_PICKERS.DISTANCE_BACKGROUND]: {
        type: CONFIGURATION_ACTION_TYPES.DISTANCE_CARD_COLOR,
        value: distanceColor
      },
      [COLOR_PICKERS.DISTANCE_TEXT]: {
        type: CONFIGURATION_ACTION_TYPES.DISTANCE_CARD_TEXT_COLOR,
        value: distanceTextColor
      }
    };

    debounceConfiguration.current(actionLookup[activeColorPicker]);
  }, [
    activeColorPicker,
    buttonColor,
    dispatchConfiguration,
    distanceColor,
    distanceTextColor,
    fontColor
  ]);

  // When customization options or its parent scrolls
  // hide the color picker
  useEffect(() => {
    colorRefLookup.current = {
      [COLOR_PICKERS.BUTTON]: buttonColorRef.current,
      [COLOR_PICKERS.TEXT]: textColorRef.current,
      [COLOR_PICKERS.DISTANCE_BACKGROUND]: distanceColorRef.current,
      [COLOR_PICKERS.DISTANCE_TEXT]: distanceTextColorRef.current
    };

    const scrollCallback = () => {
      if (activeColorPicker !== null) {
        setActiveColorPicker(null);
      }
    };

    const configurationWrapperElem = configurationWrapperRef.current;
    const customizationWrapperElem = customizationWrapperRef.current;

    if (configurationWrapperElem && customizationWrapperElem) {
      configurationWrapperElem.addEventListener("scroll", scrollCallback);
      customizationWrapperElem.addEventListener("scroll", scrollCallback);
    }

    window.addEventListener("resize", scrollCallback);

    hasMounted.current = true;

    return () => {
      if (configurationWrapperElem && customizationWrapperElem) {
        configurationWrapperElem.removeEventListener("scroll", scrollCallback);
        customizationWrapperElem.removeEventListener("scroll", scrollCallback);
      }

      window.removeEventListener("resize", scrollCallback);
    };
  }, [
    customizationWrapperRef,
    configurationWrapperRef,
    activeColorPicker,
    setActiveColorPicker,
    distanceTextColorRef,
    hasMounted,
    colorRefLookup,
    buttonColorRef,
    textColorRef,
    distanceColorRef,
    distanceTextColor,
    showOverlay
  ]);

  const SolutionAndLanguageFields = [
    <div className="block mb-5">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="network" className="text-gray-700 text-sm">
        Solution
      </label>
      <select
        id="network"
        defaultValue={network}
        className="form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900 cy-network-select"
        onChange={(evt) => {
          history.replace(getConfiguratorPath(locale, evt.target.value, style));
        }}>
        {Object.entries(LABEL_NETWORK_LOOKUP).map((networkLocale) => (
          <option key={networkLocale[0]} value={networkLocale[0]}>
            {networkLocale[1]}
          </option>
        ))}
      </select>
    </div>,

    <div className="block mb-5">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="language" className="text-gray-700 text-sm">
        Language
      </label>
      <select
        id="language"
        defaultValue={locale}
        className="form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900 cy-locale-select"
        onChange={(evt) => {
          history.replace(getConfiguratorPath(evt.target.value, network, style));
        }}>
        {Object.entries(LABEL_LOCALE_LOOKUP).map((labelLocale) => (
          <option key={labelLocale[0]} value={labelLocale[0]}>
            {labelLocale[1]}
          </option>
        ))}
      </select>
    </div>,

    <div className="block mb-5">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="styles" className="text-gray-700 text-sm">
        Styles
      </label>
      <select
        id="styles"
        defaultValue={style}
        className="form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900 cy-style-select"
        onChange={(evt) => {
          history.replace(getConfiguratorPath(locale, network, evt.target.value));
        }}>
        {Object.entries(LABEL_STYLE_LOOKUP).map((styleLocale) => (
          <option key={styleLocale[0]} value={styleLocale[0]}>
            {styleLocale[1]}
          </option>
        ))}
      </select>
    </div>
  ];

  return (
    <div className="c-configurator">
      <div className="flex justify-center c-configurator__switcher">
        <div className="o-device-wrap">
          <DeviceSwitch isMobile={isMobile} setIsMobile={setIsMobile} />
        </div>
      </div>
      <section
        ref={configurationWrapperRef}
        className="c-configurator__config flex flex-col h-full overflow-auto bg-gray-50">
        {(showOverlay || checkoutStep === 1) && (
          <div className={classNames("p-10 pb-5")}>
            <h1 className="border border-b-0 text-xl border-l-0 border-r-0 border-gray-300 border-t-1 pt-5">
              Customization
            </h1>
          </div>
        )}

        {!showOverlay && checkoutStep === 1 && (
          <div
            className={classNames(
              "pt-0 c-configurator__options p-10 overflow-auto o-fade-in__long cy-web-component-options"
            )}>
            {SolutionAndLanguageFields}
          </div>
        )}

        {showOverlay && (
          <div
            ref={customizationWrapperRef}
            className={classNames(
              "pt-0 c-configurator__options p-10 overflow-auto o-fade-in__long cy-widget-options"
            )}>
            <div ref={colorPickerWrapperRef} className="absolute z-10">
              {activeColorPicker !== null && (
                <div className="o-fade-in">
                  <HexColorPicker
                    color={colorPickerStateLookup[activeColorPicker][0]}
                    onChange={colorPickerStateLookup[activeColorPicker][1]}
                  />
                </div>
              )}
            </div>
            <div ref={buttonColorRef} className="relative">
              {SolutionAndLanguageFields}

              <label htmlFor="bopis" className="block mb-5">
                <span className="text-gray-700 text-sm">BOPIS</span>
                <select
                  id="bopis"
                  defaultValue={isBopisState ? BOPIS_VALUES.enabled : BOPIS_VALUES.disabled}
                  className="cy-bopis-select form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900"
                  onChange={(evt) => {
                    setIsBopisState(evt.target.value !== DEFAULT_BOPIS_VALUE);
                  }}>
                  {BOPIS_INPUT_PROPS.map(({ title, value }) => (
                    <option key={title} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </label>

              <div className="block mb-5">
                {/*
                  Removed the association because the association between label and input
                  was causing the onBlur not to fire
               */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-gray-700 text-sm">Button Color</label>
                <input
                  id="button-color"
                  readOnly
                  onBlur={() => {
                    setActiveColorPicker(null);
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                      toggleColorPicker(COLOR_PICKERS.BUTTON);
                    }
                  }}
                  onClick={() => {
                    toggleColorPicker(COLOR_PICKERS.BUTTON);
                  }}
                  value={buttonColor}
                  className="cy-button-color-input border rounded border-gray-500 focus:outline-none w-full px-4 py-2  font-light placeholder-gray-500 text-gray-900 mt-1"
                />
              </div>
            </div>
            <div ref={textColorRef} className="relative">
              <div className="block mb-5">
                {/*
                  Removed the association because the association between label and input
                  was causing the onBlur not to fire
               */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-gray-700 text-sm">Text Color</label>
                <input
                  id="text-color"
                  readOnly
                  onBlur={() => {
                    setActiveColorPicker(null);
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                      toggleColorPicker(COLOR_PICKERS.TEXT);
                    }
                  }}
                  onClick={() => {
                    toggleColorPicker(COLOR_PICKERS.TEXT);
                  }}
                  value={fontColor}
                  className="cy-text-color-input border rounded border-gray-500 focus:outline-none w-full px-4 py-2 font-light placeholder-gray-500 text-gray-900 mt-1"
                />
              </div>
            </div>
            <div ref={distanceColorRef}>
              <div className="block mb-5">
                {/*
                  Removed the association because the association between label and input
                  was causing the onBlur not to fire
               */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-gray-700 text-sm">Distance Card Color</label>
                <input
                  id="distance-card-bg-color"
                  readOnly
                  onBlur={() => {
                    setActiveColorPicker(null);
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                      toggleColorPicker(COLOR_PICKERS.DISTANCE_BACKGROUND);
                    }
                  }}
                  onClick={() => {
                    toggleColorPicker(COLOR_PICKERS.DISTANCE_BACKGROUND);
                  }}
                  value={distanceColor}
                  className="cy-distance-card-color-input border rounded border-gray-500 focus:outline-none w-full px-4 py-2 font-light placeholder-gray-500 text-gray-900 mt-1"
                />
              </div>
            </div>
            <div ref={distanceTextColorRef}>
              <div className="block mb-5">
                {/*
                  Removed the association because the association between label and input
                  was causing the onBlur not to fire
               */}
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-gray-700 text-sm">Distance Card Text Color</label>
                <input
                  id="distance-card-text-color"
                  readOnly
                  onBlur={() => {
                    setActiveColorPicker(null);
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                      toggleColorPicker(COLOR_PICKERS.DISTANCE_TEXT);
                    }
                  }}
                  onClick={() => {
                    toggleColorPicker(COLOR_PICKERS.DISTANCE_TEXT);
                  }}
                  value={distanceTextColor}
                  className="cy-distance-card-text-color-input border rounded border-gray-500 focus:outline-none w-full px-4 py-2 font-light placeholder-gray-500 text-gray-900 mt-1"
                />
              </div>
            </div>

            <label htmlFor="fonts" className="block mb-5 ">
              <span className="text-gray-700 text-sm">Font</span>
              <select
                id="fonts"
                defaultValue={configuration.theme?.text?.header?.font}
                className="cy-font-select form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900"
                onChange={(evt) => {
                  dispatchConfiguration({
                    type: CONFIGURATION_ACTION_TYPES.FONT,
                    value: evt.target.value
                  });
                }}>
                {FONT_INPUT_PROPS.map(({ title, value }) => (
                  <option key={title} value={value}>
                    {title}
                  </option>
                ))}
              </select>
            </label>
            <div className="block mb-5">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="header-font-weight" className="text-gray-700 text-sm">
                Header Font Weight
              </label>
              <select
                id="header-font-weight"
                defaultValue={configuration.theme?.text?.header?.fontWeight}
                className="cy-header-font-weight-select form-select block w-full mt-1 font-light rounded bg-none pr-3 text-gray-900"
                onChange={(evt) => {
                  dispatchConfiguration({
                    type: CONFIGURATION_ACTION_TYPES.HEADER_FONT_WEIGHT,
                    value: evt.target.value
                  });
                }}>
                {HEADER_FONT_WEIGHT.map(({ title, value }) => (
                  <option key={title} value={value}>
                    {title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </section>

      <div className="flex flex-row justify-center items-start c-configurator__checkout">
        <MobilePhone
          iframeRef={iframeRef}
          showOverlay={showOverlay}
          isMobile={isMobile}
          locale={initialLocaleParamRef.current}
          network={initialNetworkParamRef.current}
          hbStyle={initialStyleParamRef.current}
        />
      </div>
    </div>
  );
};
