import { Network } from "./App.types";

export const getConfiguratorPath = (language?: string, network?: string, style?: string): string =>
  `/configurator/language/${language}/solution/${network}/style/${style}/`;

export const getCheckoutPath = (language?: string, network?: string, style?: string): string =>
  `/checkout/language/${language}/solution/${network}/style/${style}/`;

export const ROUTES = {
  CHECKOUT: "/checkout/(language)?/:locale?/(solution)?/:network/(style)?/:style?",
  CONFIGURATOR: "/configurator/(language)?/:locale?/(solution)?/:network?/(style)?/:style?",
  TESTING_SHIPPING_METHODS: "/testing/shipping-methods"
};

export const isNetwork = (network?: string): network is Network =>
  !!network && ["hubbox", "ups", "dpd", "hermes", "evri"].includes(network);
