import React, { ReactElement, useEffect, useRef, useState } from "react";
import { HbWidgetModal } from "@hubbox/web-components/dist/components/widget-modal/widget-modal.react.component";
import { HbCorePickupConfirmation } from "@hubbox/web-components/dist/components/core-pickup-confirmation/core-pickup-confirmation.react.component";
import "../../App.css";
import SingleWidgetManager from "@hubbox/single-widget-manager";
import { ClipLoader } from "react-spinners";
import { useIsMobile } from "../../hooks/useIsMobile";
import Billing from "./Billing";
import { SHIPPING_METHOD_TEST_CONFIG_IDS } from "../../App.consts";
import { CollectPointResponse } from "../../types/CollectPointResponse";
import { ReactComponent as UPSIcon } from "../images/ups.svg";
import BenefitsInfo from "./BenefitsInfo";
import { ReactComponent as PlantLogo } from "../images/plant-logo.svg";
import Input from "./Input/Input";
import ShippingMethod from "./ShippingMethod/ShippingMethod";
import Basket from "./Basket/Basket";
import CheckoutConfirmation from "./CheckoutConfirmation/CheckoutConfirmation";
import { Method } from "../types/Method.types";
import countryCurrencyCounty from "../helpers/countryCurrencyCounty";
import { DeliveryForm } from "./DeliveryForm/DeliveryForm";

/**
 *
 * Checkout Component
 *
 *
 * Controls the logic of the checkout
 *
 */
function Checkout(): ReactElement {
  const [collectPoint, setCollectPoint] = useState<CollectPointResponse>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const singleWidgetManagerRef = useRef<SingleWidgetManager>();

  const widgetModalComponentRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();

  const shippingMethods: Method[] = [
    {
      inputId: "free-local-pickup",
      inputText: "FREE Local Pickup",
      price: 0,
      expectedDeliveryPeriod: "1 to 7 business days",
      icon: <UPSIcon />,
      benefitsInfo: <BenefitsInfo />
    },
    {
      inputId: "express-local-pickup",
      inputText: "Express Local Pickup",
      price: 6.99,
      expectedDeliveryPeriod: "1 to 3 business days",
      icon: <UPSIcon />
    },
    {
      inputId: "standard-home-shipping",
      inputText: "Standard Home Shipping",
      price: 9.99,
      expectedDeliveryPeriod: "1 to 7 business days"
    },
    {
      inputId: "express-home-shipping",
      inputText: "Express Home Shipping",
      price: 16.99,
      expectedDeliveryPeriod: "1 to 3 business days"
    },
    {
      inputId: "expedited-home-shipping",
      inputText: "Expedited Home Shipping",
      price: 24.99,
      expectedDeliveryPeriod: "1 to 3 business days"
    }
  ];

  const [selectedMethod, setSelectedMethod] = useState<Method>({
    inputId: "standard-home-shipping",
    inputText: "Standard Home Shipping",
    price: 9.99,
    expectedDeliveryPeriod: "1 to 7 business days"
  });

  const [selectedCountry, setSelectedCountry] = useState("GB");

  /**
   * When component mounts instantiate the SingleWidgetManager
   * and add event listeners
   *
   * (It is hidden in an overlay, to give the fastest possible load time)
   *
   * */
  useEffect(() => {
    if (widgetModalComponentRef.current) {
      if (singleWidgetManagerRef.current) {
        singleWidgetManagerRef.current.iframeElem.remove();
        singleWidgetManagerRef.current.resetMessageManager();
        singleWidgetManagerRef.current.events.unsubscribe(
          singleWidgetManagerRef.current.topics.subscribe.COLLECT_POINT_CONFIRMED
        );
      }

      singleWidgetManagerRef.current = new SingleWidgetManager({
        deferRender: true,
        // FIXME: When adding the following into production.env file and running yarn build:production,
        // for some reason this variable is evaluating to undefined:
        // In production.env:
        // SHIPPING_METHOD_TEST_WIDGET_IFRAME_URL=https://frame.hub-box.com
        // Added here - not working:
        // iframeUrl: process.env.SHIPPING_METHOD_TEST_WIDGET_IFRAME_URL,
        iframeUrl: "https://frame.hub-box.com",
        iframeParams: {
          configId:
            countryCurrencyCounty[selectedCountry] &&
            SHIPPING_METHOD_TEST_CONFIG_IDS[selectedCountry]
        }
      });

      widgetModalComponentRef.current.appendChild(singleWidgetManagerRef.current.iframeElem);

      singleWidgetManagerRef.current.events.subscribe(
        singleWidgetManagerRef.current.topics.subscribe.COLLECT_POINT_CONFIRMED,
        (evt) => {
          setIsWidgetOpen(false);
          setCollectPoint(evt.message as CollectPointResponse);
          setShowConfirmation(true);
        }
      );
    }
  }, [selectedCountry, setCollectPoint]);

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  enum PaymentStatus {
    Unstarted,
    Started,
    Finished
  }

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.Unstarted);

  const fakeNetworkCall = (milliseconds: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  const handlePayment = async () => {
    setPaymentStatus(PaymentStatus.Started);

    await fakeNetworkCall(2000);

    setPaymentStatus(PaymentStatus.Finished);
  };

  const [contactInfo, setContactInfo] = useState<string>("");

  return (
    <section className="font-body font-light">
      {paymentStatus !== PaymentStatus.Finished && (
        <>
          <HbWidgetModal
            noHeader
            id="open-modal-no-header"
            ref={widgetModalComponentRef}
            open={isWidgetOpen}
            onHb-modal-closed={() => setIsWidgetOpen(false)}
          />

          <div className="flex" style={{ flexGrow: 2 }}>
            <div className="border-gray flex flex-col" style={{ flexGrow: 3 }}>
              <div className="flex">
                <div className="p-5 md:p-11">
                  <header className="mb-5 md:mb-10">
                    <PlantLogo />
                  </header>
                  {isMobile && (
                    <Basket selectedMethod={selectedMethod} selectedCountry={selectedCountry} />
                  )}

                  <section>
                    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-4 md:mb-5 mt-5">Contact</h1>
                    <div className="mb-10">
                      <Input<string>
                        id="email-or-phone-number"
                        labelText="Email or phone number"
                        placeholder="joesmith@gmail.com"
                        setStateMethod={setContactInfo}
                        stateValue={contactInfo}
                      />
                    </div>
                  </section>

                  <section>
                    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-4 md:mb-5">
                      Shipping Address
                    </h1>
                    <DeliveryForm
                      collectPoint={collectPoint}
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                  </section>

                  <ShippingMethod
                    shippingMethods={shippingMethods}
                    selectedMethod={selectedMethod}
                    setSelectedMethod={setSelectedMethod}
                    setIsWidgetOpen={setIsWidgetOpen}
                    selectedCountry={selectedCountry}
                  />

                  {showConfirmation ? (
                    <HbCorePickupConfirmation
                      collectPoint={collectPoint}
                      className="pickup-confirmation"
                      locale="en-US"
                    />
                  ) : null}

                  <section>
                    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-4 md:mb-5">Payment</h1>

                    <form className="w-full">
                      <Billing />

                      <h2 className="text-lg md:text-xl lg:text-2xl mb-2 md:mb-4">
                        Billing address
                      </h2>

                      <DeliveryForm
                        collectPoint={collectPoint}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                      />

                      {paymentStatus === PaymentStatus.Unstarted && (
                        <button
                          className="bg-hubbox-green w-full p-2.5 text-white tracking-widest rounded-md"
                          type="button"
                          onClick={handlePayment}>
                          Pay now
                        </button>
                      )}

                      {paymentStatus === PaymentStatus.Started && (
                        <div className="flex justify-center">
                          <ClipLoader className="" />
                        </div>
                      )}
                    </form>
                  </section>
                </div>
                {!isMobile && (
                  <Basket selectedMethod={selectedMethod} selectedCountry={selectedCountry} />
                )}
              </div>
              <hr />
              <footer
                className="flex flex-col md:flex-row 
              items-center gap-6 py-4 mt-5 border-solid border-hubbox-gray-border-1 my-0 mx-auto">
                <PlantLogo />
                <button type="button" className="text-hubbox-green">
                  Refund Policy
                </button>
                <button type="button" className="text-hubbox-green">
                  Shipping Policy
                </button>
                <button type="button" className="text-hubbox-green">
                  Privacy Policy
                </button>
              </footer>
            </div>
          </div>
        </>
      )}

      {paymentStatus === PaymentStatus.Finished && (
        <>
          <div className="p-5 md:p-11">
            <header className="mb-5 md:mb-10">
              <PlantLogo />
            </header>
            {collectPoint ? (
              <CheckoutConfirmation
                isPickup
                address={collectPoint.address}
                name={collectPoint.name}
                selectedMethod={selectedMethod}
                selectedCountry={selectedCountry}
                contactInfo={contactInfo}
              />
            ) : (
              <CheckoutConfirmation
                isPickup={false}
                selectedMethod={selectedMethod}
                selectedCountry={selectedCountry}
                contactInfo={contactInfo}
              />
            )}
          </div>
          <hr />
          <footer
            className="flex flex-col md:flex-row justify-center
          items-center gap-6 py-4 mt-5 border-solid border-hubbox-gray-border-1">
            <PlantLogo />
            <button type="button" className="text-hubbox-green">
              Refund Policy
            </button>
            <button type="button" className="text-hubbox-green">
              Shipping Policy
            </button>
            <button type="button" className="text-hubbox-green">
              Privacy Policy
            </button>
          </footer>
        </>
      )}
    </section>
  );
}

export default Checkout;
