import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import hubboxLogo from "./hubbox.logo.svg";
import { LABEL_LOCALE_LOOKUP, LABEL_NETWORK_LOOKUP, LABEL_STYLE_LOOKUP } from "./Landing.consts";
import { Language, Network, Style } from "./Landing.types";
import { getConfiguratorPath } from "../App.helpers";

export const Landing = (): ReactElement => {
  const history = useHistory();

  return (
    <div className="bg-gray-600 h-screen w-screen flex justify-center items-center bg-color-outer-space">
      <div className="flex flex-col items-center">
        <img className="mb-10" width="104px" alt="" src={hubboxLogo} />

        <h1 className="text-center text-white text-xl mb-2">Welcome to the HubBox Demo</h1>

        <p className="text-center text-white text-sm mb-8">
          Please select language and solution to get started
        </p>

        <form
          onSubmit={(evt) => {
            evt.preventDefault();

            const { network, language, style } = Array.from(
              evt.currentTarget.querySelectorAll("select")
            ).reduce<Partial<{ network: Network; language: Language; style: Style }>>(
              (current, selectEl) => ({
                [selectEl.name]: selectEl.value,
                ...current
              }),
              {}
            );

            history.push(getConfiguratorPath(language, network, style));
          }}>
          <label className="mb-5 block" htmlFor="language">
            <div className="text-white mb-2">Language</div>
            <select
              id="language"
              name="language"
              className="bg-transparent w-full border-white text-white rounded u-select-arrow cy-locale-select">
              {/* bg-color-outer-space is here on option for browsers on windows. Without it the text would not appear on a white background */}
              {Object.entries(LABEL_LOCALE_LOOKUP).map((locale) => (
                <option className="bg-color-outer-space" key={locale[0]} value={locale[0]}>
                  {locale[1]}
                </option>
              ))}
            </select>
          </label>

          <label className="block mb-5" htmlFor="network">
            <div className="text-white mb-2">Solution</div>
            <select
              id="network"
              name="network"
              className="bg-transparent w-full border-white text-white rounded u-select-arrow cy-network-select">
              {/* bg-color-outer-space is here on option for browsers on windows. Without it the text would not appear on a white background */}
              {Object.entries(LABEL_NETWORK_LOOKUP).map((network) => (
                <option className="bg-color-outer-space" key={network[0]} value={network[0]}>
                  {network[1]}
                </option>
              ))}
            </select>
          </label>

          <label className="block mb-10" htmlFor="style">
            <div className="text-white mb-2">Style</div>
            <select
              id="style"
              name="style"
              className="bg-transparent w-full border-white text-white rounded u-select-arrow cy-style-select">
              {Object.entries(LABEL_STYLE_LOOKUP).map((style) => (
                <option className="bg-color-outer-space" key={style[0]} value={style[0]}>
                  {style[1]}
                </option>
              ))}
            </select>
          </label>

          <button
            type="submit"
            className="border-white text-white rounded border w-full p-2 uppercase tracking-widest">
            See Demo
          </button>
        </form>
      </div>
    </div>
  );
};
