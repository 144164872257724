import classNames from "classnames";
import React, { ReactElement } from "react";
import iphoneFrameImage from "../../iphone_frame.png";
import iphoneFrameImageGrey from "../../iphone_frame_grey_background.png";
import { MobilePhoneProps } from "./MobilePhone.types";
import { getCheckoutPath } from "../../../App.helpers";

const MobilePhone = ({
  isMobile,
  showOverlay,
  iframeRef,
  locale,
  network,
  hbStyle
}: MobilePhoneProps): ReactElement => (
  <div
    className={classNames({
      "o-phone-img-wrap": isMobile,
      "h-full": !isMobile,
      "w-full": !isMobile
    })}>
    {/* preload mobile image on desktop view */}
    <img
      alt=""
      src={iphoneFrameImage}
      className={classNames("opacity-100 transition-opacity", {
        "opacity-0": showOverlay,
        "sr-only": !isMobile
      })}
    />

    <img
      alt=""
      src={iphoneFrameImageGrey}
      className={classNames("opacity-0 absolute top-0 transition-opacity", {
        "opacity-100": showOverlay,
        "sr-only": !isMobile
      })}
    />
    <div
      className={classNames({
        "o-phone-img-wrap__iframe": isMobile,
        "h-full": !isMobile,
        "w-full": !isMobile
      })}>
      <iframe
        ref={iframeRef}
        title="checkout"
        src={`${window.origin}${getCheckoutPath(locale, network, hbStyle)}`}
        width="100%"
        height="100%"
      />
    </div>
  </div>
);

export default MobilePhone;
