import React, { ReactElement } from "react";
import classNames from "classnames";
import { Input } from "../Input";

export const DeliveryForm = React.forwardRef<HTMLDivElement>(
  (props, ref): ReactElement => (
    <div ref={ref} className="c-floating-input mb-5 relative">
      {[
        { placeholder: "John Smith", id: "name", labelText: "Name" },
        {
          type: "email",
          placeholder: "name@example.com",
          id: "email",
          labelText: "Email address"
        },
        {
          placeholder: "123 Test Street",
          id: "address-line-1",
          labelText: "Address Line 1"
        },
        {
          placeholder: "Test Town",
          id: "address-line-2",
          labelText: "Address Line 2"
        }
      ].map(({ placeholder, type, id, labelText }) => (
        <div className="mb-2" key={id}>
          <Input type={type} placeholder={placeholder} id={id} labelText={labelText} />
        </div>
      ))}
      <div className="flex">
        {[
          {
            placeholder: "Atlanta",
            id: "city",
            labelText: "City"
          },
          {
            placeholder: "Georgia",
            id: "state",
            labelText: "State"
          },
          {
            placeholder: "30303",
            id: "zip-code",
            labelText: "ZIP Code"
          }
        ].map(({ placeholder, id, labelText }, index) => (
          <div key={id} className={classNames("mb-2 flex-grow", { "mr-2": index < 2 })}>
            <Input placeholder={placeholder} id={id} labelText={labelText} />
          </div>
        ))}
      </div>
    </div>
  )
);
