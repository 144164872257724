import React, { ReactElement, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import Checkout from "./Checkout/Checkout";
import { Message } from "./types/Message";
import { Configuration } from "./types/Configuration";
import { DeepPartial } from "./types/Utilies/DeepPartial";
import {
  CHECKOUT_MESSAGE_TYPE,
  CONFIGURATOR_MESSAGE_TYPE,
  DEFAULT_THEME,
  defaultThemeLookup
} from "./App.consts";
import { configurationReducer } from "./Configurator/Configurator.reducer";
import { CONFIGURATION_ACTION_TYPES } from "./Configurator/Configurator.consts";
import { CollectPointResponse } from "./types/CollectPointResponse";
import { UrlParameters } from "./App.types";
import { isNetwork } from "./App.helpers";

const AppCheckout = (): ReactElement => {
  const [checkoutStep, setCheckoutStep] = useState(1);

  /**
   * If you are changing this configuration then please also change
   * the `testdrive` in dynamo DB.
   *
   * When we develop the initial theme step, the configuration
   * can be fetched using the configuration api. It can then be
   * passed to this component
   */
  const [showOverlay, setShowOverlay] = useState(false);
  const [collectPoint, setCollectPoint] = useState<CollectPointResponse>();

  const [params, setParams] = useState<Partial<UrlParameters>>({});
  let { network, locale } = params;

  const { network: networkRouterParam, locale: languageRouterParam } = useParams<UrlParameters>();

  /* This is not modifying state as we a modifying primitive */
  if (!network) {
    network = networkRouterParam;
  }

  /* This is not modifying state as we a modifying primitive */
  if (!locale) {
    locale = languageRouterParam;
  }

  /**
   * As network is set as a url param then update configuration
   * inline with network. This mirrors configurator we have it here
   * so checkout works without configurator being a parent.
   * It should update configuration faster.
   *
   */
  const [configuration, dispatchConfiguration] = useReducer(
    configurationReducer,
    isNetwork(network)
      ? configurationReducer(defaultThemeLookup[network], {
          type: CONFIGURATION_ACTION_TYPES.NETWORK,
          value: network,
          meta: {
            isBopis: false
          }
        })
      : DEFAULT_THEME
  );

  /**
   * Checkout step updated it has updated the checkout step
   * send a message to the Configurator
   */
  useEffect(() => {
    window.parent.postMessage(
      {
        topic: CHECKOUT_MESSAGE_TYPE.STEP_UPDATED,
        payload: checkoutStep
      },
      "*"
    );
  }, [checkoutStep]);

  /**
   * Overlay has been shown
   * send a message to the Configurator
   */
  useEffect(() => {
    window.parent.postMessage(
      {
        topic: CHECKOUT_MESSAGE_TYPE.SHOW_OVERLAY,
        payload: showOverlay
      },
      "*"
    );
  }, [showOverlay]);

  /**
   * Collect point has been selected
   * send a message to the Configurator
   */
  useEffect(() => {
    window.parent.postMessage(
      {
        topic: CHECKOUT_MESSAGE_TYPE.COLLECT_POINT,
        payload: collectPoint
      },
      "*"
    );
  }, [collectPoint]);

  /**
   * Listen for params updated event from the configurator
   */
  useEffect(() => {
    const handler = (data: MessageEvent<Message>) => {
      if (data.data.topic === CONFIGURATOR_MESSAGE_TYPE.PARAMS_UPDATED) {
        setParams(data.data.payload as UrlParameters);
      }

      if (data.data.topic === CONFIGURATOR_MESSAGE_TYPE.CONFIG_UPDATED) {
        dispatchConfiguration({
          type: CONFIGURATION_ACTION_TYPES.CONFIGURATION,
          value: data.data.payload as DeepPartial<Configuration>
        });
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  return (
    <Checkout
      configuration={configuration}
      checkoutStep={checkoutStep}
      showOverlay={showOverlay}
      setShowOverlay={setShowOverlay}
      setCollectPoint={setCollectPoint}
      collectPoint={collectPoint}
      setCheckoutStep={setCheckoutStep}
      network={network}
      locale={locale}
    />
  );
};

export default AppCheckout;
