import React, { ReactElement } from "react";
import { ReactComponent as Store } from "../images/store.svg";
import { ReactComponent as Lock } from "../images/lock.svg";
import { ReactComponent as Leaf } from "../images/leaf.svg";

const BenefitsInfo = (): ReactElement => (
  <div className="flex flex-col gap-3">
    <div className="flex items-center gap-2">
      <span>
        <Store />
      </span>
      Over 20,000+ locations
    </div>
    <div className="flex items-center gap-2">
      <span>
        <Lock />
      </span>
      Secure and guaranteed delivery
    </div>
    <div className="flex items-center gap-2">
      <span>
        <Leaf />
      </span>
      85% reduced carbon footprint!
    </div>
  </div>
);

export default BenefitsInfo;
