import React, { ReactElement } from "react";
import { ShippingMethodProps } from "./ShippingMethod.types";
import DeliveryOption from "../DeliveryOption/DeliveryOption";

const ShippingMethod = ({
  shippingMethods,
  selectedMethod,
  setSelectedMethod,
  setIsWidgetOpen,
  selectedCountry
}: ShippingMethodProps): ReactElement => (
  <section>
    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-4 md:mb-5 mt-10">Shipping Method</h1>

    <div className="mb-10 flex flex-col items-start self-stretch">
      <>
        {shippingMethods.map(
          ({ inputId, inputText, expectedDeliveryPeriod, price, icon, benefitsInfo }) => (
            <DeliveryOption
              key={inputId}
              inputId={inputId}
              inputText={inputText}
              price={price}
              expectedDeliveryPeriod={expectedDeliveryPeriod}
              icon={icon}
              benefitsInfo={benefitsInfo}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
              setIsWidgetOpen={setIsWidgetOpen}
              selectedCountry={selectedCountry}
            />
          )
        )}
      </>
    </div>
  </section>
);

export default ShippingMethod;
