import { LatLngArray } from "./types/LatLngArray";
import { DeepPartial } from "./types/Utilies/DeepPartial";
import { Configuration } from "./types/Configuration";
import { WidgetTheme } from "./types/WidgetTheme";
import { Networks } from "./types/Networks";
import {
  DEFAULT_FONT_VALUE,
  DEFAULT_HEADER_FONT_WEIGHT,
  DPD_NETWORK,
  HUBBOX_NETWORK,
  UPS_NETWORK,
  HERMES_NETWORK,
  EVRI_NETWORK
} from "./Configurator/Configurator.consts";

export const LAT_LNG_NEW_YORK = [40.716, -74.00447] as LatLngArray;
export const LAT_LNG_LONDON = [51.505, -0.09] as LatLngArray;

export const LAT_LNG_BOPIS_HUBBOX = [51.502978, -0.1109629] as LatLngArray;
export const LAT_LNG_BOPIS_HERMES = [47.1048651, 15.3960991] as LatLngArray;
export const LAT_LNG_BOPIS_DPD = [51.5133764, -0.1237792] as LatLngArray;
export const LAT_LNG_BOPIS_UPS = [40.7400605, -73.9945934] as LatLngArray;
export const LAT_LNG_BOPIS_EVRI = [51.5133764, -0.1237792] as LatLngArray;

export const CHECKOUT_MESSAGE_TYPE = {
  STEP_UPDATED: "STEP_UPDATED",
  SHOW_OVERLAY: "SHOW_OVERLAY",
  COLLECT_POINT: "COLLECT_POINT"
};

export const CONFIGURATOR_MESSAGE_TYPE = {
  CONFIG_UPDATED: "CONFIG_UPDATED",
  PARAMS_UPDATED: "PARAMS_UPDATED"
};

export const LOCALE_CODES = {
  EN: "en",
  EN_US: "en-us",
  DE: "de",
  FR: "fr",
  ES: "es",
  IT: "it",
  NL: "nl"
} as const;

export const NETWORKS = {
  UPS: "ups",
  HUBBOX: "hubbox",
  DPD: "dpd",
  HERMES: "hermes",
  EVRI: "evri"
} as const;

export const CONFIG_IDS = {
  [NETWORKS.UPS]: UPS_NETWORK,
  [NETWORKS.HUBBOX]: HUBBOX_NETWORK,
  [NETWORKS.DPD]: DPD_NETWORK,
  [NETWORKS.HERMES]: HERMES_NETWORK,
  [NETWORKS.EVRI]: EVRI_NETWORK
} as const;

export const SHIPPING_METHOD_TEST_CONFIG_IDS = {
  GB: "dpd",
  US: "ups_us",
  CA: "ups_ca",
  FR: "mondial_relay"
};

export const STYLES = {
  DEFAULT: "default",
  DEMO: "demo"
} as const;

/**
 * If you are changing the default theme, then please change Dynamo DB aswell
 */

export const DEFAULT_THEME: DeepPartial<Configuration> = {
  general: {
    theme: WidgetTheme.TOOLTIP
  },
  theme: {
    text: {
      header: {
        font: DEFAULT_FONT_VALUE,
        fontWeight: DEFAULT_HEADER_FONT_WEIGHT
      },
      primary: {
        font: DEFAULT_FONT_VALUE
      }
    },
    icons: {
      pin: {
        [Networks.PRIVATE]: `${process.env.REACT_APP_FRAME_CDN}/demo-assets/your-store-pin.png`,

        // Add in UPS pins as the default ups pin is not branded
        // We can't use ups defaultConfig in config as that changes button colors
        // which would override whatever the user has chosen
        [Networks.UPS]: `${process.env.REACT_APP_FRAME_CDN}/demo-assets/hw-pin-ups-unselected.svg`
      },
      pinSelected: {
        [Networks.PRIVATE]: `${process.env.REACT_APP_FRAME_CDN}/demo-assets/your-store-pin.png`,
        // Add in UPS pins as the default ups pin is not branded
        // We can't use ups defaultConfig in config as that changes button colors
        // which would override whatever the user has chosen
        [Networks.UPS]: `${process.env.REACT_APP_FRAME_CDN}/demo-assets/hw-pin-ups.svg`
      }
    }
  }
};

/**
 * If you are changing the default theme, then please change Dynamo DB aswell
 */

export const DEFAULT_THEME_HUBBOX: DeepPartial<Configuration> = {
  ...DEFAULT_THEME,
  general: {
    ...DEFAULT_THEME.general,
    retailerSlug: HUBBOX_NETWORK
  }
};

/**
 * If you are changing the default theme, then please change Dynamo DB aswell
 */

export const DEFAULT_THEME_UPS: DeepPartial<Configuration> = {
  ...DEFAULT_THEME,
  general: {
    ...DEFAULT_THEME.general,
    retailerSlug: UPS_NETWORK
  }
};

/**
 * If you are changing the default theme, then please change Dynamo DB aswell
 */

export const DEFAULT_THEME_DPD: DeepPartial<Configuration> = {
  ...DEFAULT_THEME,
  general: {
    ...DEFAULT_THEME.general,
    retailerSlug: DPD_NETWORK
  }
};

/**
 * If you are changing the default theme, then please change Dynamo DB aswell
 */

export const DEFAULT_THEME_HERMES: DeepPartial<Configuration> = {
  ...DEFAULT_THEME,
  general: {
    ...DEFAULT_THEME.general,
    retailerSlug: HERMES_NETWORK
  }
};

export const DEFAULT_THEME_EVRI: DeepPartial<Configuration> = {
  ...DEFAULT_THEME,
  general: {
    ...DEFAULT_THEME.general,
    retailerSlug: EVRI_NETWORK
  }
};

export const defaultThemeLookup = {
  [Networks.UPS]: DEFAULT_THEME_UPS,
  [Networks.HUBBOX]: DEFAULT_THEME_HUBBOX,
  [Networks.DPD]: DEFAULT_THEME_DPD,
  [Networks.HERMES]: DEFAULT_THEME_HERMES,
  [Networks.EVRI]: DEFAULT_THEME_EVRI
};

export const DEFAULT_NETWORK = NETWORKS.UPS;

export const PLACEHOLDER_USER_DATA = {
  name: "John Smith",
  email: "johnsmith@email.com",
  street1: "123 Test Street",
  street2: "Test Town",
  street3: "Atlanta",
  street4: "Georgia",
  postcode: "30303"
};
