import { useState, useEffect } from "react";

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false);

  function handleResize(): null {
    const width = window.innerWidth;
    if (width <= 767) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return null;
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
