/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ReactElement, useEffect, useLayoutEffect, useRef, useState } from "react";
import "../App.css";
import classNames from "classnames";
import SingleWidgetManager from "@hubbox/single-widget-manager";

import { Orchestrator } from "@hubbox/web-components-orchestrator";
import {
  HbLaunchGroup,
  HbLaunchHome,
  HbLaunchSearch,
  HbPickupConfirmation,
  HbWidgetModal
} from "@hubbox/web-components";
import { Billing } from "./components/Billing";
import { CheckoutConfirmation } from "./components/CheckoutConfirmation";
import { Sunglasses } from "./components/Sunglasses";
import { CheckoutProps, LocaleManagerEventParam } from "./Checkout.types";
import { usePrevious } from "../shared/helpers/hooks/usePrevious";
import { scrollToTop } from "./helpers/scrollTo/scrollTo";
import { DeliveryForm } from "./components/DeliveryForm/DeliveryForm";
import { CONFIG_IDS, DEFAULT_NETWORK, PLACEHOLDER_USER_DATA } from "../App.consts";
import { isNetwork } from "../App.helpers";
import { Networks } from "../types/Networks";
import { AddressFormatCode } from "../types/AddressFormat";
import { CollectPointResponse } from "../types/CollectPointResponse";
import { Configuration } from "../types/Configuration";
import { DeepPartial } from "../types/Utilies/DeepPartial";
import { STATIC_MAP_CONFIG_ID } from "./Checkout.consts";
import DeliveryOption from "./components/DeliveryOption/DeliveryOption";
import { getCurrencySymbol } from "./helpers/misc";

/**
 *
 * Checkout Component
 *
 *
 * Controls the logic of the checkout
 *
 * @param checkoutStep
 * @param setCheckoutStep
 * @param singleWidgetManagerRef
 * @param showOverlay
 * @param setShowOverlay
 * @param collectPoint
 * @param setCollectPoint
 * @param configuration
 * @constructor
 */
function Checkout({
  checkoutStep,
  setCheckoutStep,
  showOverlay,
  setShowOverlay,
  collectPoint,
  setCollectPoint,
  configuration,
  network,
  locale
}: CheckoutProps): ReactElement {
  const [displayDelivery, setDisplayDelivery] = useState(false);

  const configurationRef = useRef<DeepPartial<Configuration>>();
  const singleWidgetManagerRef = useRef<SingleWidgetManager>();

  const hasMounted = useRef(false);

  const billingStepRef = useRef<HTMLDivElement>(null);
  const deliveryStepRef = useRef<HTMLDivElement>(null);
  const deliveryFormRef = useRef<HTMLDivElement>(null);

  const pickupConfirmationComponentRef = useRef(new HbPickupConfirmation());
  const widgetModalComponentRef = useRef(new HbWidgetModal());
  const launchGroupComponentRef = useRef(new HbLaunchGroup());
  const launchHomeComponentRef = useRef(new HbLaunchHome());
  const launchSearchComponentRef = useRef(new HbLaunchSearch());
  const localeManagerEventParamRef = useRef<LocaleManagerEventParam>();

  const prevConfiguration = usePrevious(configuration, {});

  /**
   * When component mounts instantiate the SingleWidgetManager
   * and add event listeners
   *
   * (It is hidden in an overlay, to give the fastest possible load time)
   *
   * */
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (singleWidgetManagerRef.current) return;

    // eslint-disable-next-line no-param-reassign
    singleWidgetManagerRef.current = new SingleWidgetManager({
      deferRender: true,
      iframeUrl: process.env.REACT_APP_WIDGET_IFRAME_URL,
      iframeParams: {
        configId: CONFIG_IDS[network ?? DEFAULT_NETWORK],
        locale
      }
    });

    pickupConfirmationComponentRef.current.configId = STATIC_MAP_CONFIG_ID;

    pickupConfirmationComponentRef.current.iframeUrl =
      process.env.REACT_APP_WIDGET_IFRAME_URL || "";

    widgetModalComponentRef.current.addEventListener("hb-modal-opened", () => {
      window.document.body.classList.add("overflow-hidden");
    });

    widgetModalComponentRef.current.addEventListener("hb-modal-closed", () => {
      window.document.body.classList.remove("overflow-hidden");
    });

    launchSearchComponentRef.current.addEventListener("hb-search-form-submit", () => {
      setDisplayDelivery(false);
    });

    launchHomeComponentRef.current.addEventListener("hb-select-button-click", () => {
      setDisplayDelivery(true);
      setCollectPoint(undefined);
    });

    widgetModalComponentRef.current.addEventListener("hb-modal-opened", () => {
      setShowOverlay(true);
    });

    widgetModalComponentRef.current.addEventListener("hb-modal-closed", () => {
      setShowOverlay(false);
    });

    singleWidgetManagerRef.current.events.subscribe(
      singleWidgetManagerRef.current.topics.subscribe.COLLECT_POINT_CONFIRMED,
      (evt) => {
        if (singleWidgetManagerRef.current) {
          setCollectPoint(evt.message as CollectPointResponse);
        }
      }
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const orchestrator = new Orchestrator({
      singleWidgetManager: singleWidgetManagerRef.current,
      components: {
        pickupConfirmationComponent: pickupConfirmationComponentRef.current,
        modalComponent: widgetModalComponentRef.current,
        launchGroupComponent: launchGroupComponentRef.current,
        launchHomeComponent: launchHomeComponentRef.current,
        launchSearchComponent: launchSearchComponentRef.current
      },
      selectors: {
        launchGroupSelector: {
          selector: ".js-launch-group",
          insertPosition: "afterbegin"
        },
        pickupConfirmationSelector: {
          selector: ".js-pickup-confirmation",
          insertPosition: "afterbegin"
        },
        modalSelector: {
          selector: ".js-container",
          insertPosition: "afterbegin"
        }
      },
      logLevel: Orchestrator.logLevels.DEBUG,
      enableLaunchMutationObserver: true,
      enablePickupConfirmationMutationObserver: true,
      callbacks: {
        widget: {
          reset: () => {
            /**
             *
             * In the Widget
             * SET_LOCALE relies on state configuration and locale param to create a new configuration.
             * SET_CONFIGURATION relies on state locale and the configuration param to create a new configuration.
             *
             * So the asynchronous nature of post message is OK here as both events will produce a new configuration.
             * The last event received will use the previous event's value in state.
             *
             */
            if (singleWidgetManagerRef.current) {
              singleWidgetManagerRef.current.events.emit(
                singleWidgetManagerRef.current.topics.emit.SET_CONFIGURATION,
                // eslint-disable-next-line @typescript-eslint/ban-types
                configurationRef.current as object
              );

              singleWidgetManagerRef.current.events.emit(
                singleWidgetManagerRef.current.topics.emit.SET_LOCALE,
                localeManagerEventParamRef.current
              );
            }
          }
        }
      }
    });

    orchestrator.appendWidgetToModal();
  }, [singleWidgetManagerRef, locale, setShowOverlay, setCollectPoint, configuration, network]);

  // Could Orchestrator manage this?
  useEffect(() => {
    if (singleWidgetManagerRef.current) {
      singleWidgetManagerRef.current.events.emit(
        singleWidgetManagerRef.current.topics.emit.SET_LOCALE,
        locale
      );

      widgetModalComponentRef.current.locale = locale;
      pickupConfirmationComponentRef.current.locale = locale;
      launchHomeComponentRef.current.locale = locale;
      launchSearchComponentRef.current.locale = locale;
      localeManagerEventParamRef.current = locale;
    }
  }, [locale]);

  // Could Orchestrator manage this?
  useEffect(() => {
    if (network) {
      widgetModalComponentRef.current.network = isNetwork(network) ? network : DEFAULT_NETWORK;
      launchHomeComponentRef.current.network = isNetwork(network) ? network : DEFAULT_NETWORK;
      launchSearchComponentRef.current.network = isNetwork(network) ? network : DEFAULT_NETWORK;
    }
  }, [network]);

  // Could this go in orchestrator?
  useEffect(() => {
    pickupConfirmationComponentRef.current.addressFormat =
      collectPoint?.meta.network === Networks.UPS ? AddressFormatCode.US : AddressFormatCode.GB;
  }, [collectPoint]);

  /**
   *
   * If there is a change to configuration from the Configurator
   * then set it on the widget
   *
   * */
  useEffect(() => {
    if (configuration) {
      configurationRef.current = configuration;
    }

    if (
      singleWidgetManagerRef.current &&
      configuration &&
      hasMounted.current &&
      prevConfiguration
    ) {
      if (JSON.stringify(prevConfiguration.general) !== JSON.stringify(configuration.general)) {
        singleWidgetManagerRef.current.events.emit(
          singleWidgetManagerRef.current.topics.emit.SET_CONFIGURATION,
          // eslint-disable-next-line @typescript-eslint/ban-types
          configuration as object
        );
      } else {
        singleWidgetManagerRef.current.events.emit(
          singleWidgetManagerRef.current.topics.emit.SET_CONFIGURATION_THEME,
          // eslint-disable-next-line @typescript-eslint/ban-types
          configuration.theme
        );
      }
    }
  }, [configuration, singleWidgetManagerRef, prevConfiguration]);

  /**
   *
   * As the user moves through the steps we want the
   * browser window to scroll to the top
   *
   * */
  useEffect(() => {
    // Scroll To top for confirmation and restart checkout
    if (checkoutStep === 4 || checkoutStep === 1) {
      window.scrollTo({ top: 0 });
    }
  }, [checkoutStep]);

  /**
   *
   * When Checkout step has changed scroll to relevant component
   *
   * */
  useEffect(() => {
    if (checkoutStep === 2 && deliveryStepRef.current) {
      scrollToTop(deliveryStepRef.current.offsetTop);
    }

    if (checkoutStep === 3 && billingStepRef.current) {
      scrollToTop(billingStepRef.current.offsetTop);
    }
  }, [checkoutStep]);

  useEffect(() => {
    if (displayDelivery && deliveryFormRef.current) {
      scrollToTop(deliveryFormRef.current.offsetTop);
    }
  }, [displayDelivery]);

  /**
   *
   * When Component Mounts set hasMounted ref to true
   *
   * */
  useEffect(() => {
    hasMounted.current = true;
  }, [hasMounted]);

  /**
   *
   * Helper getCheckoutButton to correct button styling
   *
   * */
  const getCheckoutButton = (step: number, title: string) =>
    checkoutStep > step ? (
      <button
        type="button"
        onClick={() => {
          setCheckoutStep(step);
        }}
        className={`text-2xl md:text-3xl lg:text-4xl text-gray-400 font-light block block mb-5 cy-checkout-step-button-${step}`}>
        {title}
      </button>
    ) : (
      <h1 className="text-2xl md:text-3xl lg:text-4xl text-gray-400 font-light block mb-5">
        {title}
      </h1>
    );

  return (
    <section className="font-body font-light container js-container mx-auto p-4">
      {checkoutStep < 4 ? (
        <>
          <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5 md:mb-10  uppercase tracking-widest">
            Checkout
          </h1>

          <div
            className={classNames("flex flex-col md:flex-row", {
              "delivery-is-displaying": displayDelivery
            })}>
            <div className="lg:border-r lg:pr-10 border-gray w-full lg:w-3/3">
              {checkoutStep === 1 ? (
                <section>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5 md:mb-10">
                    1. Shipping Location
                  </h1>
                  <div className="mb-10 js-launch-group" />
                  {displayDelivery ? (
                    <DeliveryForm ref={deliveryFormRef} />
                  ) : (
                    <div aria-live="polite">
                      <div className="mb-10 pt-5 js-pickup-confirmation" />
                    </div>
                  )}

                  <button
                    onClick={() => {
                      setCheckoutStep(2);
                    }}
                    disabled={!collectPoint && !displayDelivery}
                    className={classNames(
                      "cy-continue-delivery bg-black uppercase w-full p-2.5 mb-10 text-white tracking-widest rounded-lg",
                      {
                        "bg-gray-300": !collectPoint && !displayDelivery
                      }
                    )}
                    type="button">
                    Continue to delivery
                  </button>
                </section>
              ) : (
                getCheckoutButton(1, " 1. Shipping Location")
              )}

              {checkoutStep === 2 ? (
                <section ref={deliveryStepRef}>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5 md:mb-10">
                    2. Delivery Options
                  </h1>

                  <div className="mb-10">
                    {network === Networks.DPD ? (
                      <DeliveryOption
                        inputId="next-day-delivery"
                        inputText="DPD Next Day Delivery"
                      />
                    ) : (
                      <>
                        {[
                          {
                            inputId: "standard-delivery",
                            inputText: "Standard Delivery (5-7 days)"
                          },
                          {
                            inputId: "express-delivery",
                            inputText: "Express Delivery (2-4 days)"
                          }
                        ].map(({ inputId, inputText }) => (
                          <DeliveryOption key={inputId} inputId={inputId} inputText={inputText} />
                        ))}
                      </>
                    )}
                  </div>

                  <button
                    onClick={() => {
                      setCheckoutStep(3);
                    }}
                    className="cy-continue-billing bg-black uppercase w-full p-2.5 mb-10 text-white tracking-widest rounded-lg"
                    type="button">
                    Continue to billing
                  </button>
                </section>
              ) : (
                getCheckoutButton(2, "2. Delivery Options")
              )}

              {checkoutStep === 3 ? (
                <section ref={billingStepRef}>
                  <h1 className="text-2xl md:text-3xl lg:text-4xl  mb-5 md:mb-10">3. Billing</h1>

                  <form className="w-full">
                    <Billing />

                    <button
                      onClick={() => {
                        setCheckoutStep(4);
                      }}
                      className="cy-complete-order bg-black uppercase w-full p-2.5 text-white tracking-widest rounded-lg"
                      type="button">
                      Complete Order
                    </button>
                  </form>
                </section>
              ) : (
                getCheckoutButton(3, "3. Billing")
              )}
            </div>
            <div className="hidden lg:block lg:w-1/3">
              <section className="pl-10">
                <h1 className="uppercase text-1xl text-gray-900 mb-5 md:mb-10">Your Basket</h1>

                <div className="flex justify-between">
                  <div className="flex">
                    <div className="w-28">
                      <Sunglasses />
                    </div>

                    <b className="ml-5">Sunglasses</b>
                  </div>
                  <div>
                    <b>{getCurrencySymbol(locale)}40</b>
                  </div>
                </div>
                <div className="flex justify-between mt-6">
                  <div className="flex flex-col">
                    <strong>Total</strong>
                    <span>Excluding tax & shipping</span>
                  </div>
                  <div>
                    <strong>{getCurrencySymbol(locale)}40</strong>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-5">
            {collectPoint ? (
              <CheckoutConfirmation
                isPickup
                address={collectPoint.address}
                name={collectPoint.name}
                locale={locale}
              />
            ) : (
              <CheckoutConfirmation
                isPickup={false}
                address={{
                  street1: PLACEHOLDER_USER_DATA.street1,
                  street2: PLACEHOLDER_USER_DATA.street2,
                  street3: PLACEHOLDER_USER_DATA.street3,
                  street4: PLACEHOLDER_USER_DATA.street4,
                  postcode: PLACEHOLDER_USER_DATA.postcode
                }}
                name={PLACEHOLDER_USER_DATA.name}
                locale={locale}
              />
            )}
          </div>

          <button
            className="underline"
            type="button"
            onClick={() => {
              setCheckoutStep(1);
              setCollectPoint(undefined);
              setDisplayDelivery(false);
            }}>
            Restart Checkout
          </button>
        </>
      )}
    </section>
  );
}

export default Checkout;
