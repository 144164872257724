import React, { ReactElement } from "react";
import { Sunglasses } from "../Sunglasses";
import { Address } from "../Address";
import { CheckoutConformationProps } from "./CheckoutConfirmation.types";
import { PLACEHOLDER_USER_DATA } from "../../../App.consts";
import { getCurrencySymbol } from "../../helpers/misc";

const CheckoutConfirmation = ({
  address,
  name,
  isPickup,
  locale
}: CheckoutConformationProps): ReactElement => (
  <>
    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5 md:mb-10 uppercase tracking-widest">
      Thank you for your order
    </h1>
    <p className="text-1xl md:text-2xl lg:text-3xl mb-10">
      We’re working on your order and you should receive a confirmation email shortly. <br /> Your
      order details are below.
    </p>

    <h2 className="text-1xl md:text-2xl lg:text-3xl font-normal mb-5">Order - 12345678</h2>

    <div className="grid grid-cols-2 md:grid-cols-4 cy-confirmation">
      <div className="mb-10 md:mb-0 mr-10">
        <Sunglasses />
      </div>

      <div>
        <span className="inline-block ml-0 lg:ml-10">Sunglasses</span>
        <b className="inline-block ml-5 md:ml-10 font-normal">{getCurrencySymbol(locale)}40</b>
      </div>

      <div>
        <div>
          <div className="font-normal">Email</div>
          <div className="mb-5">{PLACEHOLDER_USER_DATA.email}</div>

          <div className="font-normal">Payment Method</div>
          <div className="mb-5">Visa XXXX-XXXX-XXXX-4567</div>

          <div className="font-normal">Order date</div>
          <div>01/01/2021</div>
        </div>
      </div>

      <div>
        <div className="md:ml-10">
          <div className="font-normal">Delivery Option</div>
          <div className="mb-5">Standard</div>

          {isPickup ? (
            <div className="font-normal">Pickup Location</div>
          ) : (
            <div className="font-normal">Delivery Address</div>
          )}
          <div>
            <Address
              name={name}
              street1={address.street1}
              street2={address.street2}
              street3={address.street3}
              street4={address.street4}
              county={address.county}
              postcode={address.postcode}
              city={address.city}
              region={address.region}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default CheckoutConfirmation;
