import React, { ReactElement } from "react";
import { ReactComponent as CheckoutItem } from "../../images/checkout-item.svg";
import { CheckoutConformationProps } from "./CheckoutConfirmation.types";
import { Address } from "../../../Checkout/components/Address";
import countryCurrencyCounty from "../../helpers/countryCurrencyCounty";

// FIXME: Given a collect point has not been selected, and a user has filled in the shipping address fields manually
// when a user clicks the pay button, then the checkout confirmation does not show the manually filled in address.
// Only the address of a selected collect point address will be displayed in the checkout confirmation.
// In either scenario, we want the address to be shown.
// May need to rework the CollectPointInput and Input components to achieve this.
const CheckoutConfirmation = ({
  address = {
    street1: null,
    street2: undefined,
    street3: undefined,
    street4: undefined,
    city: undefined,
    postcode: undefined,
    county: undefined,
    locality: undefined,
    point: undefined,
    region: undefined,
    country: undefined,
    latitude: undefined,
    longitude: undefined,
    location: undefined
  },
  name = "",
  isPickup = false,
  selectedCountry,
  selectedMethod,
  contactInfo
}: CheckoutConformationProps): ReactElement => (
  <>
    <h1 className="text-2xl md:text-3xl lg:text-4xl mb-5 md:mb-10 uppercase tracking-widest">
      Thank you for your order
    </h1>
    <p className="text-1xl md:text-2xl lg:text-3xl mb-10">
      We’re working on your order and you should receive a confirmation email shortly. <br /> Your
      order details are below.
    </p>

    <h2 className="text-1xl md:text-2xl lg:text-3xl font-normal mb-5">Order - 12345678</h2>

    <div className="grid grid-cols-2 md:grid-cols-4 cy-confirmation">
      <div className="mb-10 md:mb-0 mr-10">
        <CheckoutItem />
      </div>

      <div>
        <span className="inline-block ml-0 lg:ml-10">Monstera Deliciosa</span>
        <b className="inline-block ml-5 md:ml-10 font-normal">
          {countryCurrencyCounty[selectedCountry] &&
            countryCurrencyCounty[selectedCountry].currency}
          100
        </b>
      </div>

      <div>
        <div>
          <div className="font-normal">Contact Information</div>
          <div className="mb-5">{contactInfo}</div>

          <div className="font-normal">Payment Method</div>
          <div className="mb-5">Visa XXXX-XXXX-XXXX-1111</div>

          <div className="font-normal">Order Date</div>
          <div>{new Date(Date.now()).toLocaleDateString()}</div>
        </div>
      </div>

      <div>
        <div className="md:ml-10">
          <div className="font-normal">Delivery Option</div>
          <div className="mb-5">
            {selectedMethod && (
              <div>
                <div>{selectedMethod.inputText}</div>
                <div>{selectedMethod.expectedDeliveryPeriod}</div>
                <div>
                  {countryCurrencyCounty[selectedCountry] &&
                    countryCurrencyCounty[selectedCountry].currency}
                  {selectedMethod.price}
                </div>
              </div>
            )}
          </div>

          {isPickup ? (
            <div className="font-normal">Pickup Location</div>
          ) : (
            <div className="font-normal">Delivery Address</div>
          )}
          <div>
            <Address
              name={name}
              street1={address.street1}
              street2={address.street2}
              street3={address.street3}
              street4={address.street4}
              county={address.county}
              postcode={address.postcode}
              city={address.city}
              region={address.region}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default CheckoutConfirmation;
