import React, { ReactElement, useState } from "react";
import classNames from "classnames";
import { ReactComponent as CheckoutItem } from "../../images/checkout-item.svg";
import { ReactComponent as Cart } from "../../images/cart.svg";
import { ReactComponent as Chevron } from "../../images/chevron.svg";
import countryCurrencyCounty from "../../helpers/countryCurrencyCounty";
import { BasketProps } from "./Basket.types";

const Basket = ({ selectedMethod, selectedCountry }: BasketProps): ReactElement => {
  const itemPrice = 100.0;
  const itemTax = 5.0;

  let shippingCost = 0;

  if (selectedMethod && selectedMethod.price) {
    shippingCost = selectedMethod.price;
  }

  const total = itemPrice + itemTax + shippingCost;

  const currency =
    countryCurrencyCounty[selectedCountry] && countryCurrencyCounty[selectedCountry].currency;

  const [mobileHideOrder, setMobileHideOrder] = useState(true);

  const toggleMobileHideOrder = () => {
    setMobileHideOrder(!mobileHideOrder);
  };

  return (
    <div className="bg-trueGray-100 flex-grow md:pt-11 md:px-6" style={{ maxWidth: "416px" }}>
      <div className="md:hidden bg-trueGray-100 px-5 flex justify-between py-3 border-t border-b border-solid border-hubbox-gray-border-2">
        <button type="button" className="flex gap-2 items-center" onClick={toggleMobileHideOrder}>
          <Cart />
          <p className="text-hubbox-green">Show order summary</p>
          <Chevron />
        </button>
        <p className="font-semibold">
          {currency}
          {total}
        </p>
      </div>

      <div
        className={classNames("flex flex-col gap-5 bg-trueGray-100 px-5 py-3", {
          "mobile-basket-hide-order": mobileHideOrder
        })}>
        <div className="flex justify-between gap-3 mb-3">
          <div className="flex gap-4">
            <div>
              <CheckoutItem />
            </div>
            <div className="flex flex-col">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <p style={{ textWrap: "nowrap" }}>
                <b>Monstera Deliciosa</b>
              </p>
              <p>2 feet</p>
            </div>
          </div>
          <div>
            <b>
              {currency}
              {itemPrice}
            </b>
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-3">
          <div className="flex justify-between">
            <p>Subtotal</p>
            <p>
              {currency}
              {itemPrice}
            </p>
          </div>
          <div className="flex justify-between">
            <p>Shipping</p>
            {selectedMethod ? (
              <div className="flex gap-3">
                <p>{selectedMethod.inputText}</p>
                <p>
                  {currency}
                  {selectedMethod.price}
                </p>
              </div>
            ) : (
              <p>Calculated at next step</p>
            )}
          </div>
          <div className="flex justify-between">
            <p>Taxes</p>
            <p>
              {currency}
              {itemTax}
            </p>
          </div>
        </div>
        <hr />
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p>
              <strong>Total</strong>
            </p>
          </div>
          <div>
            <strong>
              {currency}
              {total}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Basket;
