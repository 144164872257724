import { DeepPartial } from "../types/Utilies/DeepPartial";
import { Configuration } from "../types/Configuration";
import { ConfiguratorAction } from "./Configurator.types";
import {
  BOPIS_DPD_NETWORK,
  BOPIS_HUBBOX_NETWORK,
  BOPIS_UPS_NETWORK,
  BOPIS_HERMES_NETWORK,
  CONFIGURATION_ACTION_TYPES,
  DPD_NETWORK,
  HUBBOX_NETWORK,
  UPS_NETWORK,
  HERMES_NETWORK,
  EVRI_NETWORK,
  BOPIS_EVRI_NETWORK
} from "./Configurator.consts";
import {
  LAT_LNG_BOPIS_DPD,
  LAT_LNG_BOPIS_EVRI,
  LAT_LNG_BOPIS_HERMES,
  LAT_LNG_BOPIS_HUBBOX,
  LAT_LNG_BOPIS_UPS,
  LAT_LNG_LONDON,
  LAT_LNG_NEW_YORK
} from "../App.consts";
import { Networks } from "../types/Networks";

export const configurationReducer = (
  configuration: DeepPartial<Configuration>,
  action: ConfiguratorAction
): DeepPartial<Configuration> => {
  if (action.type === CONFIGURATION_ACTION_TYPES.CONFIGURATION) {
    return action.value;
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.BUTTON_COLOR) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        buttons: {
          ...configuration?.theme?.buttons,
          global: {
            ...configuration?.theme?.buttons?.global,
            backgroundColor: action.value
          }
        }
      }
    };
  }
  if (action.type === CONFIGURATION_ACTION_TYPES.FONT) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        text: {
          ...configuration?.theme?.text,
          primary: {
            ...configuration?.theme?.text?.primary,
            font: action.value
          },
          header: {
            ...configuration?.theme?.text?.header,
            font: action.value
          }
        }
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.TEXT_COLOR) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        text: {
          ...configuration?.theme?.text,
          primary: {
            ...configuration?.theme?.text?.primary,
            color: action.value
          },
          header: {
            ...configuration?.theme?.text?.header,
            color: action.value
          },
          secondary: {
            ...configuration?.theme?.text?.secondary,
            color: action.value
          }
        },
        components: {
          ...configuration?.theme?.components,
          notification: {
            ...configuration?.theme?.components?.notification,
            color: action.value
          }
        }
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.HEADER_FONT_WEIGHT) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        text: {
          ...configuration?.theme?.text,
          header: {
            ...configuration?.theme?.text?.header,
            fontWeight: action.value
          }
        }
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.DISTANCE_CARD_COLOR) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        components: {
          ...configuration?.theme?.components,
          tag: {
            ...configuration?.theme?.components?.tag,
            backgroundColor: action.value
          }
        }
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.DISTANCE_CARD_TEXT_COLOR) {
    return {
      ...configuration,
      theme: {
        ...configuration?.theme,
        components: {
          ...configuration?.theme?.components,
          tag: {
            ...configuration?.theme?.components?.tag,
            color: action.value
          }
        }
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.NETWORK && action.value === Networks.DPD) {
    return {
      ...configuration,
      general: {
        ...configuration.general,
        defaultLatLng: action.meta.isBopis ? LAT_LNG_BOPIS_DPD : LAT_LNG_LONDON,
        retailerSlug: action.meta.isBopis ? BOPIS_DPD_NETWORK : DPD_NETWORK
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.NETWORK && action.value === Networks.UPS) {
    return {
      ...configuration,
      general: {
        ...configuration.general,
        defaultLatLng: action.meta.isBopis ? LAT_LNG_BOPIS_UPS : LAT_LNG_NEW_YORK,
        retailerSlug: action.meta.isBopis ? BOPIS_UPS_NETWORK : UPS_NETWORK
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.NETWORK && action.value === Networks.HUBBOX) {
    return {
      ...configuration,
      general: {
        ...configuration.general,
        defaultLatLng: action.meta.isBopis ? LAT_LNG_BOPIS_HUBBOX : LAT_LNG_LONDON,
        retailerSlug: action.meta.isBopis ? BOPIS_HUBBOX_NETWORK : HUBBOX_NETWORK
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.NETWORK && action.value === Networks.HERMES) {
    return {
      ...configuration,
      general: {
        ...configuration.general,
        defaultLatLng: action.meta.isBopis ? LAT_LNG_BOPIS_HERMES : LAT_LNG_LONDON,
        retailerSlug: action.meta.isBopis ? BOPIS_HERMES_NETWORK : HERMES_NETWORK
      }
    };
  }

  if (action.type === CONFIGURATION_ACTION_TYPES.NETWORK && action.value === Networks.EVRI) {
    return {
      ...configuration,
      general: {
        ...configuration.general,
        defaultLatLng: action.meta.isBopis ? LAT_LNG_BOPIS_EVRI : LAT_LNG_LONDON,
        retailerSlug: action.meta.isBopis ? BOPIS_EVRI_NETWORK : EVRI_NETWORK
      }
    };
  }

  return configuration;
};
