import React, { ReactElement, useRef, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Message } from "./types/Message";
import { Configurator } from "./Configurator/Configurator";
import {
  CHECKOUT_MESSAGE_TYPE,
  CONFIGURATOR_MESSAGE_TYPE,
  defaultThemeLookup,
  DEFAULT_NETWORK
} from "./App.consts";
import { configurationReducer } from "./Configurator/Configurator.reducer";
import { UrlParameters } from "./App.types";

const AppConfigurator = (): ReactElement => {
  const params = useParams<UrlParameters>();

  const [checkoutStep, setCheckoutStep] = useState(1);

  /**
   * If you are changing this configuration then please also change
   * the `testdrive` in dynamo DB.
   *
   * When we develop the initial theme step, the configuration
   * can be fetched using the configuration api. It can then be
   * passed to this component
   */
  const [showOverlay, setShowOverlay] = useState(false);

  const iframeCheckoutRef = useRef<HTMLIFrameElement>(null);

  const [configuration, dispatchConfiguration] = useReducer(
    configurationReducer,
    defaultThemeLookup[params.network ? params.network : DEFAULT_NETWORK]
  );

  /**
   * If configuration has been updated
   * send a message to the checkout to update configuration
   */
  useEffect(() => {
    if (iframeCheckoutRef?.current?.contentWindow) {
      iframeCheckoutRef.current.contentWindow.postMessage(
        {
          topic: CONFIGURATOR_MESSAGE_TYPE.CONFIG_UPDATED,
          payload: configuration
        },
        "*"
      );
    }
  }, [configuration]);

  /**
   * If params change on configurator tell the checkout
   */
  useEffect(() => {
    if (iframeCheckoutRef?.current?.contentWindow) {
      iframeCheckoutRef.current.contentWindow.postMessage(
        {
          topic: CONFIGURATOR_MESSAGE_TYPE.PARAMS_UPDATED,
          payload: params
        },
        "*"
      );
    }
  }, [params]);

  /**
   * Listen to messages from the Checkout
   */
  useEffect(() => {
    const handler = (data: MessageEvent<Message>) => {
      if (data.data.topic === CHECKOUT_MESSAGE_TYPE.STEP_UPDATED) {
        setCheckoutStep(data.data.payload as number);
      }

      if (data.data.topic === CHECKOUT_MESSAGE_TYPE.SHOW_OVERLAY) {
        setShowOverlay(data.data.payload as boolean);
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  return (
    <Configurator
      showOverlay={showOverlay}
      dispatchConfiguration={dispatchConfiguration}
      iframeRef={iframeCheckoutRef}
      checkoutStep={checkoutStep}
      configuration={configuration}
    />
  );
};

export default AppConfigurator;
