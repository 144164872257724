import React, { ReactElement, useEffect, useState } from "react";
import { CollectPointInputProps } from "./CollectPointInput.types";

const CollectPointInput = ({
  type = "text",
  placeholder,
  id,
  labelText,
  collectPointInfo
}: CollectPointInputProps): ReactElement => {
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (collectPointInfo) {
      setInputText(collectPointInfo);
    }
  }, [collectPointInfo]);

  return (
    <div className="c-floating-input relative">
      <input
        type={type}
        id={id}
        className="border border-hubbox-gray-border-2 focus:outline-none w-full p-4 h-12 font-light placeholder-gray-500 text-gray-900"
        placeholder={placeholder}
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <label
        htmlFor={id}
        className="absolute font-medium top-0.5 left-1 px-3 py-3 h-full 
      text-gray-900 pointer-events-none transform origin-left transition-all duration-100 ease-in-out">
        {labelText}
      </label>
    </div>
  );
};

export default CollectPointInput;
