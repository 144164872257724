export enum Networks {
  HUBBOX = "hubbox",
  HERMES = "hermes",
  UPS = "ups",
  DPD = "dpd",
  EVRI = "evri",
  PRIVATE = "private"
}

export enum NetworkType {
  PUBLIC = "public",
  PRIVATE = "private"
}
